import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  appDarkBlue,
  appOrange,
  zindex,
} from "../../../../Design/SourcesJS/themes";
import BaseButton from "../../Buttons/BaseButton";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Motion } from "../../Motion/Motion";
import { ReactComponent as SaleFooter } from "./sale_footer.svg";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import { getDateFromString } from "../../../../Classes/datesControl";

function formatTime(endDate) {
  const date = new Date(endDate);
  // Get the hours and minutes
  const hours = date.getHours();
  let minutes = date.getMinutes();

  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()} ${hours}:${minutes}`;
}

const SvgBackground = ({ svg }) => {
  const editedIcon = React.cloneElement(svg, {
    style: {
      height: "auto",
      width: "100%",
      minWidth: "500px",
    },
  });
  return <>{editedIcon}</>;
};

function getTimeText(isoDate) {
  try {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return (
      <>
        Nabídka platí pouze do {formatTime(date)}
        <br />
      </>
    );
  } catch (error) {
    return "";
  }
}

const bottomText = (
  <>Nabídka platí pouze na první předplatné zakoupené během této akce.</>
);

const saleExample = {
  id: "bf",
  ed: new Date().toISOString(),
  smallTitle: "Časově omezená nabídka",
  title: "Mega sleva",
  text: "Na vybrané prémiové plány",
  buttonText: "Ušetřit",
};

function getTitle(data) {
  try {
    if (data?.title) return data.title;
    if (!data?.a) return "Mega sleva";
    let amounts = [];
    const keys = Object.keys(data.a);
    for (let i = 0; i < keys.length; i++) {
      data.a[keys[i]].map((key) => {
        amounts.push(key);
      });
    }
    const noDups = [...new Set(amounts)].filter((number) => number != 0);
    if (noDups.length === 1) {
      return `${noDups[0]}% SLEVA`;
    }
    return "Mega sleva";
  } catch (error) {
    console.log(error);
    return "Mega sleva";
  }
}

export default function SaleDialog({
  open = false,
  handleClose = () => {},
  data,
}) {
  const navigate = useNavigate();
  const imageFits = !useMediaQuery("(max-height:620px)");

  if (!data) return <></>;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: zindex.maxOverlay,
        minHeight: "100%",
        ".MuiPaper-root": {
          bgcolor: appDarkBlue,
          borderRadius: { xs: "0px", sm: "10px" },
          minWidth: "280px",
          width: { xs: "100%", sm: "580px" },
          maxWidth: { xs: "100%", sm: "580px" },
          minHeight: { xs: "100%", sm: "160px" },
          height: { xs: "100%", sm: "auto" },
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          overflowY: { xs: "scroll", sm: "hidden" },
          overflowX: "hidden",
          margin: { xs: "0px", sm: "26px", md: "32px" },
        },
      }}
    >
      <Motion>
        <Box
          sx={{
            p: "10px",
            cursor: "pointer",
            position: "absolute",
            top: 5,
            right: 5,
            height: "40px",
            width: "40px",
          }}
          onClick={handleClose}
        >
          <CloseRoundedIcon
            sx={{ fill: "white", height: "20px", width: "20px" }}
          />
        </Box>
      </Motion>
      <DialogContent
        sx={{
          width: "100%",
          pt: "80px",
          px: { xs: "40px", sm: "80px", md: "80px" },
          pb: "0px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: "320px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            fontSize: "17px",
            color: appDarkBlue,
            fontWeight: "bold",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignContent: "flex-start",
            textAlign: "center",
            p: "0px",
            height: { xs: "fit-content", sm: "auto" },
          }}
          id="alert-dialog-description"
        >
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                alignContent: "flex-end",
                overflow: "hidden",
              }}
            >
              <Typography
                component="div"
                sx={[
                  {
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    fontSize: { xs: "13px", sm: "15px" },
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    lineClamp: 1,
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    MozOrient: "vertical",
                    fontFamily: "Gordita",
                    fontWeight: "bold",
                    lineHeight: "normal",
                  },
                ]}
              >
                {data?.smallTitle || ""}
              </Typography>
            </Box>
          </Box>
          <Typography
            component="h2"
            sx={[
              {
                color: "white",
                fontFamily: "Gordita",
                fontWeight: "bold",
                textAlign: "center",
                my: "15px",
                width: "100%",
                fontSize: { xs: "38px", sm: "42px" },
                lineHeight: { xs: "46px", sm: "46px" },
              },
            ]}
          >
            {getTitle(data)}
          </Typography>
          <Typography
            component="div"
            sx={[
              {
                color: "white",
                textAlign: "center",
                width: "100%",
                fontSize: { xs: "13px", sm: "15px" },
                display: "-webkit-box",
                WebkitLineClamp: 1,
                lineClamp: 1,
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                MozOrient: "vertical",
                fontFamily: "Gordita",
                fontWeight: "bold",
                lineHeight: "normal",
              },
            ]}
          >
            {data?.text || saleExample?.text}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: imageFits ? { xs: "auto" } : "auto",
            mt: imageFits ? "0px" : "auto",
            pt: imageFits ? { xs: "100px", sm: "0px" } : "auto",
          }}
        >
          <BaseButton
            text={data?.buttonText || "Ušetřit"}
            onClick={(e) => {
              navigate("/plany");
              handleClose();
            }}
            sxProps={{
              mx: "20px",
              textAlign: "center",
              mb: imageFits ? "0px" : "20px",
              mt: { xs: "38px", sm: "50px" },
              maxWidth: "200px",
              bgcolor: appOrange,
              "&:hover": {
                bgcolor: appOrange,
              },
            }}
            sxText={{
              fontSize: "15px",
              lineHeight: "16px",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }}
          />
        </Box>
      </DialogContent>
      {data.svg && imageFits && (
        <Box
          sx={{
            bgcolor: appDarkBlue,
            position: "absolute",
            bottom: 0,
            height: "20px",
            width: "100%",
          }}
        />
      )}
      <Box
        sx={{
          width: "101%",
          height: "auto",
          display: "flex",
          position: "relative",
          justifyContent: "center",
        }}
      >
        {imageFits && <SvgBackground svg={<SaleFooter />} />}
        {imageFits && (
          <Typography
            variant="text"
            sx={{
              color: "white",
              fontSize: "12px",
              position: "absolute",
              textAlign: "center",
              /* ml: "auto",
              mr: "auto",*/
              mx: "15px",
              maxWidth: "380px",
              bottom: { xs: 12, sm: 14 },
            }}
          >
            {getTimeText(getDateFromString(data?.ed))}
            {bottomText}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
}
