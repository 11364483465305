import baseRoutes from "./Core/BaseRoutes";
import settingsRoutes from "./Core/SettingsRoutes";
import mainRoutes from "./Core/MainRoutes";
import actionRoutes from "./Core/ActionRoutes";
import shopRoutes from "./Core/ShopRoutes";
import endpointRoutes from "./Core/Endpoints/EndpointRoutes";

// CORE ROUTES
export const coreRoutes = [
  ...baseRoutes,
  ...settingsRoutes,
  ...mainRoutes,
  ...actionRoutes,
  ...shopRoutes,
  ...endpointRoutes,
];

export const getCoreRouteByPath = (path) => {
  for (let i = 0; i < coreRoutes.length; i++) {
    if (coreRoutes[i].path == path) return coreRoutes[i];
  }
  return { public: true };
};
