import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { appRedLight, borderRadius } from "../../../Design/SourcesJS/themes";

export default function FailedToast({ text }) {
  let [open, setOpen] = React.useState(true);

  function handleClose() {
    setOpen(false);
  }

  React.useEffect(() => {
    if (!window || !window.history || !document) return;
    window.history.replaceState({}, document.title);
  }, []);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          border: `2px solid ${appRedLight}`,
          borderRadius: borderRadius,
          bgcolor: "white",
        },
        ".MuiSvgIcon-root": {
          fill: appRedLight,
        },
      }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {text || "Něco se nepovedlo"}
      </Alert>
    </Snackbar>
  );
}
