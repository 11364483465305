import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  appDarkBlue,
  navbarBlue,
  textGrey,
} from "../../../Design/SourcesJS/themes";
import { AddCircleRounded, SearchRounded } from "@mui/icons-material";
import IconButton from "../Buttons/IconButton";

function ActionIcon({ icon, disabled }) {
  let editedIcon = React.cloneElement(icon, {
    style: {
      marginRight: "10px",
      fill: disabled ? textGrey : navbarBlue,
    },
  });

  return editedIcon;
}

// delete <DeleteForeverRoundedIcon />
// edit <EditRoundedIcon />

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const {
    list /* {icon: <comp/>, text: 'str'} */,
    onClickGetIndex /* onClickGetIndex(index) switch(index) case ... : action() */,
    index,
    sxBox = {},
    anchorOrigin = { vertical: "top", horizontal: "right" },
    transformOrigin = { vertical: "bottom", horizontal: "right" },
    sxPaper = {},
    tooltip = null,
    disabled = false,
    addText = null,
    searchText = null,
    searchFunction = () => {},
    navigateWithState,
    values,
    navigateUrl,
  } = props;

  const handleOpen = (e) => {
    document.activeElement.blur();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  return (
    <div style={{ ...sxBox }}>
      <Tooltip
        open={tooltipOpen}
        title={
          tooltip ? (
            <span style={{ fontSize: "13px" }}>{tooltip}</span>
          ) : undefined
        }
        placement={"top-start"}
        enterTouchDelay={100}
        enterDelay={100}
        enterNextDelay={200}
        disableFocusListener
        onClick={(e) => {
          if (disabled) {
            e.stopPropagation();
            e.preventDefault();
            return;
          }
          e.preventDefault();
        }}
      >
        <Box
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) return;
            setTooltipOpen(false);
            handleOpen(e);
          }}
          onMouseEnter={(e) => {
            e.preventDefault();
            if (disabled) return;
            setTooltipOpen(true);
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            if (disabled) return;
            setTooltipOpen(false);
          }}
          sx={{ ...sxBox }}
        >
          {props.children}
        </Box>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClose(e);
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            maxHeight: "400px",
            maxWidth: "450px",
          },
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "10px",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 1%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 20%)",
            opacity: 1,
            py: 0,
            ...sxPaper,
          },
          ".MuiMenu-list": {
            py: 0,
          },
          py: 0,
        }}
        autoFocus={false}
      >
        {addText && (
          <Box
            sx={{
              bgcolor: "white",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 9999,
              mb: "8px",
            }}
          >
            <TextField
              variant="standard"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                if (searchFunction) searchFunction(e);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              placeholder={searchText || ""}
              autoFocus
              sx={{
                width: "100%",
                p: "16px",
                pt: "8px",
                pb: "0px",
                position: "sticky",
                top: 0,
                left: 0,
                bgcolor: "white",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded style={{ fill: appDarkBlue }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        icon={<AddCircleRounded />}
                        tooltip={addText}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!navigateWithState || !values || !navigateUrl) {
                            console.error(
                              "navigateWithState",
                              " || values",
                              " undefined"
                            );
                            return;
                          }
                          navigateWithState(navigateUrl, values);
                        }}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
        )}
        {list &&
          list.map((data, actionIndex) => {
            if (data.hide) {
              return undefined;
            }
            return (
              <MenuItem
                key={"ActionMenuTab" + actionIndex}
                sx={{ fontSize: "15px" }}
                onClick={(e) => {
                  if (disabled) return;
                  e.preventDefault();
                  e.stopPropagation();
                  handleClose();
                  if (onClickGetIndex) onClickGetIndex(actionIndex, index);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt:
                      list.length > 1
                        ? actionIndex === 0
                          ? "8px"
                          : "0px"
                        : "3px",
                    mb:
                      list.length > 1
                        ? actionIndex === list.length - 1
                          ? "8px"
                          : "0px"
                        : "3px",
                  }}
                >
                  {data.icon && <ActionIcon icon={data.icon} />}
                  <Typography sx={{ fontWeight: "bold", mr: "8px" }}>
                    {data && typeof data === "string" ? data : data.text}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
