import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, LinearProgress } from "@mui/material";
import { navbarBlue } from "../../../Design/SourcesJS/themes";
import "./smooth_loader.css";
import { useEffect, useState } from "react";

export default function LoadingBackdrop({
  open,
  handleClose,
  linear = true,
  forced = false,
}) {
  const [openLate, setOpenLate] = useState(false);

  useEffect(() => {
    //if (!linear) return;
    if (open) {
      setTimeout(() => {
        setOpenLate(true);
      }, 2000);
    } else {
      setOpenLate(false);
    }
  }, [open]);

  if (!open) return <></>;

  if (forced) {
    return (
      <>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            bgcolor: "white",
          }}
        />
        <Box sx={{ width: "100%", position: "fixed", top: 0 }}>
          <LinearProgress
            sx={{
              ".MuiLinearProgress-barColorPrimary": {
                backgroundColor: navbarBlue,
              },
            }}
          />
        </Box>
      </>
    );
  }

  if (linear) {
    return (
      <>
        <Backdrop
          sx={{
            bgcolor: "rgba(0,0,0,0.15)",
            transition: "unset",
            zIndex: 99999999,
          }}
          open={open}
          onClick={handleClose}
        >
          <Box sx={{ width: "100%", position: "fixed", top: 0 }}>
            <LinearProgress
              sx={{
                //bgcolor: navbarBlue,
                ".MuiLinearProgress-barColorPrimary": {
                  backgroundColor: navbarBlue,
                },
              }}
            />
          </Box>
        </Backdrop>
      </>
    );
  } else {
    return (
      <Backdrop
        sx={{
          bgcolor: "rgba(0,0,0,0.15)",
          transition: "unset",
          zIndex: 99999999,
        }} //(theme) => theme.zIndex.drawer + 1
        open={open && openLate}
        onClick={handleClose}
      >
        <Box
          sx={{
            width: "100%",
            height: "200px",
            display: open && openLate ? "inline" : "none",
          }}
        >
          <div className="wrapper-bloader">
            <Box
              component={"span"}
              sx={{
                opacity: { xs: "0", sm: "1", md: "1" },
              }}
              className="circle-bload circle-bload-1"
            ></Box>

            <span className="circle-bload circle-bload-2"></span>
            <span className="circle-bload circle-bload-3"></span>
            <span className="circle-bload circle-bload-4"></span>
            <span className="circle-bload circle-bload-5"></span>
            <span className="circle-bload circle-bload-6"></span>
            <span className="circle-bload circle-bload-7"></span>

            <Box
              component={"span"}
              sx={{
                opacity: { xs: "0", sm: "1", md: "1" },
              }}
              className="circle-bload circle-bload-8"
            ></Box>
          </div>
        </Box>
      </Backdrop>
    );
  }
}
