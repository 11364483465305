import { useLocation, useNavigate } from "react-router-dom";
import InfoDialog from "../Resources/Core/Components/Feedback/InfoDialog/InfoDialog";
import SavedToast from "../Resources/Core/Components/Feedback/SavedToast";
import FailedToast from "../Resources/Core/Components/Feedback/FailedToast";
import { useEffect, useState } from "react";
import {
  isLocationStateUrl,
  isLocationsEqual,
  isRedirectState,
  shouldSaveState,
} from "../Resources/Routes/Redirecting/redirect";

export const withLocationInfo = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [locationState, setLocationState] = useState(null);
    const [dialog, setDialog] = useState(null);

    function showSucces() {
      if (!location?.state?.success) return false;
      return true;
    }

    function showError() {
      if (!location?.state?.error) return false;
      return true;
    }

    function checkInfo() {
      if (!location?.state?.info) return;
      setDialog({ text: location.state.info });
    }

    useEffect(() => {
      checkInfo();
      if (shouldSaveState(locationState, location)) {
        setLocationState(location.state);
        return;
      }

      if (isLocationStateUrl(locationState, location)) {
        if (
          (!isRedirectState(location) ||
            !isLocationsEqual(locationState, location)) &&
          Boolean(locationState)
        ) {
          navigate(location.pathname, { state: locationState });
        }
      } else if (Boolean(locationState)) {
        setLocationState(null);
        window.history.replaceState({}, document.title);
      }
    }, [location]);

    return (
      <>
        {showSucces() && <SavedToast />}
        {showError() && <FailedToast />}
        <InfoDialog
          handleClose={() => {
            setDialog(null);
          }}
          dialog={dialog}
        />
        <Component {...props} />
      </>
    );
  };

  return Wrapper;
};
