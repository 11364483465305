import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Resources/Design/Fonts/Fonts.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// Sentry error reporting
import "./Resources/Firebase/Crashlytics/SentryInit";

// For testing
// import { StrictMode } from "react";

// To not log in production builds
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// App - application core
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

reportWebVitals();
