import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoadingBackdrop from "../../Resources/Core/Components/Layouts/LoadingBackdrop";
import { auth } from "../../Resources/Firebase/init";
import { getControlUidNullable } from "../../Resources/Core/Pages/Settings/SubscriptionPage/SubUsers/Access";
import { ThemeProvider } from "@mui/material";
import { theme } from "../App";
import Navbar from "../../Resources/Core/Components/Navbars/Navbar";
import SecondaryNavbar from "../../Resources/Core/Components/Navbars/Secondary/SecondaryNavbar";

const PermissionDeniedPage = lazy(() =>
  import(
    "../../Resources/Core/Pages/Settings/SubscriptionPage/SubUsers/PermissionDenied/PermissionDeniedPage"
  )
);

const LoadingBackdropForced = () => {
  return <LoadingBackdrop open={true} linear={true} forced={true} />;
};

export const PageWrapper = ({ children }) => {
  return (
    <Suspense fallback={<LoadingBackdropForced />}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Suspense>
  );
};

export default function PrivateRoute({ route, loadVisual, authData }) {
  // Authentication state | functions
  const { userState, claims, subuserCompanies, authLoading, getPermissions } =
    authData;

  // Routing options
  const { path, permission, redirectIfAuth = false } = route;
  const isPublic = Boolean(route?.public);

  const isUserLogged = () => {
    return Boolean(userState);
  };

  // False if only using provider like Google
  const isUsingPasswordAuthentication = () => {
    if (auth?.currentUser) {
      const providerData = auth.currentUser.providerData;
      console.log("providerData ", providerData);
      if (providerData && providerData.length > 0) {
        for (let i = 0; i < providerData.length; i++) {
          if (providerData[i].providerId === "password") {
            return true;
          }
        }
      }
    }
    return false;
  };

  // Logged using provider like Google, didnt complete account yet
  const isUserNotSet = () => {
    if (
      isUserLogged() &&
      !isUsingPasswordAuthentication() &&
      claims &&
      !claims.acc_set
    ) {
      return true;
    }
    return false;
  };

  // Subusers control only
  function getCompanyLevel() {
    const uid = getControlUidNullable();
    for (let i = 0; i < subuserCompanies.length; i++) {
      console.log("Level", subuserCompanies[i].level);
      if (subuserCompanies[i].uid === uid) return subuserCompanies[i].level;
    }
    console.log("Level not found");
    return 0;
  }

  const isSubuser = () => {
    return claims?.subuser;
  };

  const hasPermission = (permission) => {
    if (!permission) return true; // There is no permission on the path
    if (Array.isArray(permission)) {
      for (let i = 0; i < permission.length; i++) {
        if (getPermissions()?.[permission[i]] === true) {
          return true;
        }
      }
      return false;
    } else {
      return getPermissions()?.[permission] === true;
    }
  };
  // -- end of subusers control

  const tutorialCompleted = () => {
    if (isSubuser()) return true;
    return userState && userState.emailVerified && !claims?.tutorial;
  };

  const emailVerified = () => {
    if (isSubuser()) return true;
    return userState && userState.emailVerified;
  };

  // Does not matter if logged or not - if not redirectIfAuth
  if (isPublic && !redirectIfAuth) {
    return <Outlet />;
  }

  // Does matter if logged in
  if (
    (!isPublic && authLoading) ||
    (userState && (!claims || Object.keys(claims).length === 0)) ||
    !loadVisual
  ) {
    return <LoadingBackdropForced />;
  }

  // Subusers - Check permission
  if (isSubuser() && !hasPermission(permission)) {
    console.log("Returning PermissionDeniedPage");
    return (
      <PageWrapper>
        {!route.noNavbar && <Navbar currentPath={route.path} />}
        {!route.noSecNavbar && <SecondaryNavbar />}
        <PermissionDeniedPage noNavbar />
      </PageWrapper>
    );
  }

  // Not logged, path is public
  else if (!isUserLogged() && isPublic) {
    return <Outlet />;
  }
  // Does matter if logged, but not logged in
  else if (!isUserLogged() && path !== "/login") {
    return <Navigate to={`/login?redirect=${encodeURIComponent(path)}`} />;
  }
  // Logged, account not set up (different providers than password)
  else if (isUserNotSet() && path !== "/registrace_prov") {
    return <Navigate to="/registrace_prov" />;
  }
  // Logged, email not verified
  else if (isUserLogged() && !emailVerified()) {
    if (path === "/sendEmail") {
      return <Outlet />;
    } else {
      return <Navigate to="/sendEmail" />;
    }
  }
  // Logged, verified, tutorial not completed
  else if (isUserLogged() && !tutorialCompleted()) {
    if (path === "/tutorial") {
      return <Outlet />;
    } else {
      return <Navigate to="/tutorial" />;
    }
  }

  /////////////
  // Subuser //
  /////////////

  // Company has account level 0
  if (
    isSubuser() &&
    getControlUidNullable() &&
    subuserCompanies &&
    getCompanyLevel() === 0 &&
    path !== "/denied" &&
    path !== "/sub/firmy"
  ) {
    return <Navigate to="/denied" />;
  }
  // No company selected
  else if (
    isSubuser() &&
    !getControlUidNullable() &&
    subuserCompanies &&
    path !== "/sub/firmy"
  ) {
    return <Navigate to="/sub/firmy" />;
  }
  // Has no company to take care of
  else if (
    isSubuser() &&
    !getControlUidNullable() &&
    subuserCompanies &&
    subuserCompanies.length === 0 &&
    path !== "/sub/firmy"
  ) {
    return <Navigate to="/sub/firmy" />;
  }
  // No company selected, list not ready yet
  else if (isSubuser() && !getControlUidNullable() && path !== "/sub/firmy") {
    return <LoadingBackdropForced />;
  }
  // Is not subuser, is on subuser page
  else if (!isSubuser() && path == "/sub/firmy") {
    return <Navigate to="/faktura/list" />;
  }

  //// COMPLETED ACCOUNT OPERATIONS

  // Tutorial completed but still on its page | redirect away
  if (tutorialCompleted() && path === "/tutorial") {
    return <Navigate to={"/"} />;
  }

  // Should be redirected
  if (isUserLogged() && redirectIfAuth && path !== redirectIfAuth) {
    return <Navigate to={redirectIfAuth} />;
  }

  // Email verified but still on its page | redirect away
  if (emailVerified() && path === "/sendEmail") {
    return <Navigate to={"/"} />;
  }

  return <Outlet />;
}
