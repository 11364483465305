import * as React from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { navbarBlue } from "../../../Design/SourcesJS/themes";
import generateUID from "../../../Firebase/generateUID";

export default function OutlineButton(props) {
  const {
    text,
    sxProps,
    leftIcon,
    rightIcon,
    filled,
    white,
    whiteBorder,
    outline = true,
    loading = false,
  } = props;

  const [entered, setEntered] = React.useState(false);

  const otherProps = { ...props };
  delete otherProps.leftIcon;
  delete otherProps.rightIcon;
  delete otherProps.sxProps;
  delete otherProps.text;
  delete otherProps.boxShadow;
  delete otherProps.filled;
  delete otherProps.white;
  delete otherProps.onMouseDown;
  delete otherProps.outline;
  delete otherProps.loading;

  const Icon = ({ icon, onClick }) => {
    const editedIcon = React.cloneElement(icon, {
      style: {
        height: "24px",
        width: "24px",
        fill: white
          ? entered
            ? `white`
            : `${navbarBlue}`
          : entered
          ? `${navbarBlue}`
          : `white`,
      },
      onClick: () => {
        console.log("click");
        if (onClick) onClick();
      },
    });
    return <>{editedIcon}</>;
  };

  return (
    <Button
      id={generateUID()}
      sx={[
        {
          height: "50px",
          width: "100%",
          maxWidth: { xs: "100%", sm: "400px" },
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
          //justifyContent: leftIcon ? "left" : rightIcon ? "right" : "center",
          justifyContent: "center",
          ".MuiButton-startIcon": { position: "absolute", left: 20 },
          ".MuiButton-endIcon": { position: "absolute", right: 20 },
          backgroundColor: white
            ? entered
              ? navbarBlue
              : "white"
            : entered
            ? "white"
            : navbarBlue,
          color: white
            ? entered
              ? "white"
              : navbarBlue
            : entered
            ? navbarBlue
            : "white",
          border: filled
            ? white
              ? whiteBorder
                ? "2px solid white"
                : `2px solid ${navbarBlue}`
              : whiteBorder
              ? "2px solid white"
              : `2px solid ${navbarBlue}`
            : 0,
          boxShadow: entered
            ? "1px 2px 5px 0px rgba(115,117,122,0.15)"
            : "1px 2px 5px 0px rgba(115,117,122,0.1)",
          "&:hover": {
            backgroundColor: white
              ? entered
                ? navbarBlue
                : "white"
              : entered
              ? "white"
              : navbarBlue,
          },

          // TEXT
          fontSize: "15px",
          lineHeight: "15px",
          fontWeight: "bold",
          alignSelf: "center",
          textTransform: "none",
        },
        outline ? {} : { border: "none" },
        sxProps ? sxProps : {},
      ]}
      variant="contained"
      startIcon={
        leftIcon && !loading ? (
          <Icon icon={leftIcon} onClick={otherProps?.onClick} />
        ) : null
      }
      endIcon={rightIcon && !loading ? <Icon icon={rightIcon} /> : null}
      onMouseEnter={() => {
        setEntered(true);
      }}
      onMouseLeave={() => {
        setEntered(false);
      }}
      onMouseDown={(e) => {
        if (props.onMouseDown) {
          props.onMouseDown(e);
        }
        setEntered(false);
      }}
      {...otherProps}
    >
      {text && !loading ? text : ""}
      {loading && (
        <CircularProgress
          sx={{ color: entered ? navbarBlue : "white" }}
          style={{ width: "28px", height: "28px" }}
        />
      )}
    </Button>
  );
}
