import {
  dateToVisual,
  timestampToDate,
} from "../Core/Components/Tables/DataTable/FakturaTable";
import isValidDate from "./isValidDate";

export function isDateOlderThanDays(date, days = 30) {
  const checkDate = new Date(date);
  // Subtract days
  let someDaysAgo = new Date();
  someDaysAgo.setDate(someDaysAgo.getDate() - days);
  // Compare
  return checkDate < someDaysAgo;
}

export function getThisYearRange() {
  const currentYear = new Date().getFullYear();

  const firstDateOfYear = new Date(currentYear, 0, 1);
  const lastDateOfYear = new Date(currentYear, 11, 31);

  return {
    firstDate: firstDateOfYear,
    lastDate: lastDateOfYear,
  };
}

export function isDateInRange(dateToCheck, startDate, endDate) {
  // Remove the time component from all dates
  const strippedDateToCheck = new Date(
    dateToCheck.getFullYear(),
    dateToCheck.getMonth(),
    dateToCheck.getDate()
  );
  const strippedStartDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const strippedEndDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  // Check if the date is in the range
  return (
    strippedDateToCheck >= strippedStartDate &&
    strippedDateToCheck <= strippedEndDate
  );
}

// Between two dates, including them
function calculateDays(startDate, endDate) {
  // Convert the input strings to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const timeDifference = end.getTime() - start.getTime();

  // Convert the difference to days and add 1 to include both start and end dates
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;

  return daysDifference;
}

export function fillMissingDates(
  data,
  startDateStats = null,
  endDateStats = null
) {
  if (!data) data = [];
  let filledData = [];
  const endDate = endDateStats || data[0].date; // Assuming data is sorted and not empty
  const startDate = startDateStats || data[data.length - 1].date;

  const daysDiff = calculateDays(startDate, endDate);
  for (let i = 0; i < daysDiff; i++) {
    let currentDateStr = new Date(startDate).setDate(
      new Date(startDate).getDate() + i
    );
    currentDateStr = new Date(currentDateStr).setHours(0, 0, 0, 0);

    let found = false;
    for (let j = 0; j < data.length; j++) {
      if (data[j].date === currentDateStr) {
        found = j;
        break;
      }
    }

    if (found !== false) {
      filledData.push(data[found]);
    } else {
      filledData.push({
        date: currentDateStr,
        list: [],
      });
    }
  }

  return filledData;
}

export function getEarlierDate(date1, date2) {
  const earlierDate = new Date(Math.min(date1.getTime(), date2.getTime()));
  return earlierDate;
}

export function addDaysToDate(date, daysToAdd) {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return result;
}

export function isFirstDateEarlier(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  return d1 < d2;
}

export function areDatesEqualByDays(date1, date2) {
  if (!date1 || !date2) return false;
  const strippedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const strippedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  return strippedDate1.getTime() === strippedDate2.getTime();
}

export function getDateQuarter(date) {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date");
  }

  const month = date.getMonth();

  // Determine the quarter based on the month
  if (month >= 0 && month <= 2) {
    return 1; // Quarter 1 (January to March)
  } else if (month >= 3 && month <= 5) {
    return 2; // Quarter 2 (April to June)
  } else if (month >= 6 && month <= 8) {
    return 3; // Quarter 3 (July to September)
  } else {
    return 4; // Quarter 4 (October to December)
  }
}

// Checks month and year
export function areDatesInSameMonth(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
}

export function getDateYesterday(date) {
  if (!isValidDate(date)) {
    return null;
  }
  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);
  return yesterday;
}

// Days - returns at max 12 days spread equally, from startDate to endDate
export function getEquallySpreadDates(startDate, endDate) {
  let numberOfDates = 11;
  const diffInDays = Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000));

  if (diffInDays < numberOfDates) {
    // If the difference is less than the desired number of dates,
    // return as many dates as possible.
    numberOfDates = Math.floor(diffInDays);
  }

  const dates = [new Date(startDate)]; // Start with the initial date

  for (let i = 1; i < numberOfDates; i++) {
    const nextDate = new Date(startDate);
    nextDate.setDate(
      startDate.getDate() + Math.round((i / numberOfDates) * diffInDays)
    );
    dates.push(nextDate);
  }

  dates.push(new Date(endDate)); // End with the endDate

  return dates;
}

export function getFirstAndLastDateOfThisYear(yearDate) {
  const date = yearDate || new Date();
  const startDate = new Date(date.getFullYear(), 0, 1);
  const endDate = new Date(date.getFullYear() + 1, 0, 0);
  return { startDate, endDate };
}

export function getDateForName() {
  const currentDate = new Date();

  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  return `${day}-${month}-${year}`;
}

export function getPreviousYearDate(inputDate) {
  const previousYearDate = new Date(inputDate);
  previousYearDate.setFullYear(inputDate.getFullYear() - 1);
  return previousYearDate;
}

export function previousQuarterDates(inputDate) {
  // Convert inputDate to a Date object
  const inputDateTime = new Date(inputDate);

  // Calculate the first day of the current quarter
  const currentQuarterFirst = new Date(
    inputDateTime.getFullYear(),
    Math.floor(inputDateTime.getMonth() / 3) * 3,
    1
  );

  // Calculate the last day of the previous quarter
  const previousQuarterLast = new Date(currentQuarterFirst - 1);
  const previousQuarterFirst = new Date(
    previousQuarterLast.getFullYear(),
    Math.floor(previousQuarterLast.getMonth() / 3) * 3,
    1
  );

  return {
    firstDatePrevQ: previousQuarterFirst,
    lastDatePrevQ: previousQuarterLast,
  };
}

export function dateLastYear(inputDate) {
  // Convert inputDate to a Date object
  const inputDateTime = new Date(inputDate);

  // Subtract one year from the input date
  const lastYear = new Date(inputDateTime);
  lastYear.setFullYear(inputDateTime.getFullYear() - 1);

  return lastYear;
}

export function firstDateOfPreviousYear(inputDate) {
  // Convert inputDate to a Date object
  const inputDateTime = inputDate ? new Date(inputDate) : new Date();

  const firstDatePreviousYear = new Date(inputDateTime.getFullYear() - 1, 0, 1);

  return firstDatePreviousYear;
}

export function currentQuarterDates(inputDate) {
  const inputDateTime = inputDate ? new Date(inputDate) : new Date();

  const currentQuarter = Math.floor(inputDateTime.getMonth() / 3);

  const firstDateCurrentQuarter = new Date(
    inputDateTime.getFullYear(),
    currentQuarter * 3,
    1
  );
  const lastDateCurrentQuarter = new Date(
    inputDateTime.getFullYear(),
    currentQuarter * 3 + 3,
    0
  );

  return {
    firstDateThisQ: firstDateCurrentQuarter,
    lastDateThisQ: lastDateCurrentQuarter,
  };
}

function isDateInCurrentQuarter(date) {
  // Get the current date
  const currentDate = new Date();

  // Calculate the quarter for the current date
  const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

  // Calculate the first day of the current quarter
  const firstDayOfQuarter = new Date(
    currentDate.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );

  // Calculate the last day of the current quarter
  const lastDayOfQuarter = new Date(
    currentDate.getFullYear(),
    currentQuarter * 3,
    0
  );

  // Check if the given date is within the current quarter
  return date >= firstDayOfQuarter && date <= lastDayOfQuarter;
}

export function lastYearQuarterDates(date) {
  const currentDate = date ? date : new Date();

  // Get the last year
  const lastYear = currentDate.getFullYear() - 1;

  // Calculate the quarter for the current date
  const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

  // Calculate the first day of the current quarter of the last year
  const firstDayOfQuarter = new Date(lastYear, (currentQuarter - 1) * 3, 1);

  let lastDayOfQuarter = new Date(lastYear, currentQuarter * 3, 0);

  /*let todayDate = new Date();
  if (isDateInCurrentQuarter(currentDate)) {
    lastDayOfQuarter.setDate(1);
    lastDayOfQuarter.setMonth(todayDate.getMonth());
    lastDayOfQuarter.setDate(todayDate.getDate());
  }*/

  return {
    lastYearFirstDate: firstDayOfQuarter,
    lastYearLastDate: lastDayOfQuarter,
  };
}

export function previousMonthDates(date) {
  const currentDate = date || new Date();

  const firstDayOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const lastDayOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  return {
    firstDayOfPrevMonth: firstDayOfPreviousMonth,
    lastDayOfPrevMonth: lastDayOfPreviousMonth,
  };
}

export function previousYearThisMonthDates(date) {
  const currentDate = date || new Date();

  const lastYear = currentDate.getFullYear() - 1;

  const firstDayOfPreviousYearMonth = new Date(
    lastYear,
    currentDate.getMonth(),
    1
  );
  const lastDayOfPreviousYearMonth = new Date(
    lastYear,
    currentDate.getMonth() + 1,
    0
  );

  return {
    firstDayOfPrevYearMonth: firstDayOfPreviousYearMonth,
    lastDayOfPrevYearMonth: lastDayOfPreviousYearMonth,
  };
}

// year is new Date()
export function getMonthRange(month, yearObject) {
  let year = yearObject.getFullYear();

  if (month < 1 || month > 12) {
    throw new Error("Invalid month. Month should be between 1 and 12.");
  }
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);

  return { startDate, endDate };
}

export function getQuarterRange(quarter, yearObject) {
  let year = yearObject.getFullYear();

  if (quarter < 1 || quarter > 4) {
    throw new Error("Invalid quarter. Quarter should be between 1 and 4.");
  }

  const startMonth = (quarter - 1) * 3 + 1; // e.g., quarter 1 starts at month 1, quarter 2 at month 4, and so on
  const endMonth = startMonth + 2;

  const startDate = new Date(year, startMonth - 1, 1);
  const endDate = new Date(year, endMonth, 0);

  return { startDate, endDate };
}

export function getLatestTime(inDate) {
  let date = timestampToDate(inDate);
  if (!isValidDate(inDate)) {
    date = new Date(inDate);
  }
  date.setHours(23, 59, 59, 999);
  return date;
}

export function getEarliestTime(inDate) {
  let date = timestampToDate(inDate);
  if (!isValidDate(inDate)) {
    date = new Date(inDate);
  }
  date.setHours(0, 0, 0, 0);
  return date;
}

export function isInCurrentMonth(date) {
  const currentDate = new Date();

  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

export const getDateFromString = (dateString) => {
  const ed = new Date(dateString);
  const timeZoneOffset = ed.getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(ed.getTime() + timeZoneOffset);
  return localDate;
};
