import { createContext, useContext, useEffect, useState } from "react";
import { auth, subusersHandler } from "../Resources/Firebase/init";
import {
  deleteControlUid,
  getControlUidNullable,
  setControlUid,
} from "../Resources/Core/Pages/Settings/SubscriptionPage/SubUsers/Access";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { mainErrorMessage } from "../Resources/Classes/errorHandling";
import LoadingBackdrop from "../Resources/Core/Components/Layouts/LoadingBackdrop";

export const ClaimsContext = createContext();

export const useClaimsContext = () => useContext(ClaimsContext);

export const withAuth = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const [userState, setUserState] = useState(null);
    const [subuserCompanies, setSubuserCompanies] = useState(null);
    const [claims, setClaims] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);

    // Subusers companies permissions
    const getPermissions = () => {
      const uid = getControlUidNullable();
      if (!uid || !subuserCompanies) return {};
      for (let i = 0; i < subuserCompanies.length; i++) {
        if (subuserCompanies[i].uid === uid)
          return subuserCompanies[i].permissions;
      }
      return {};
    };

    useEffect(() => {
      // Authentication state changed
      const unsubscribe = auth.onAuthStateChanged((authUser) => {
        console.log("AuthStateChange ", authUser);

        // Sets current auth user
        if (authUser) {
          setUserState(authUser);

          // Load custom claims
          authUser.getIdTokenResult(true).then(async (idTokenResult) => {
            if (claims) return;
            // Sets current users customClaims
            console.log("CustomClaims ", idTokenResult.claims);
            setClaims(idTokenResult.claims);

            // Check if subuser - load companies, permissions locally
            if (idTokenResult?.claims?.subuser) {
              if (subuserCompanies) return;
              let companies;

              // Loading subusers companies
              try {
                const compRes = await subusersHandler(
                  { uid: authUser.uid },
                  "getSubuserCompanies"
                );
                companies = compRes.data;
              } catch (err) {
                // Failed to load companies
                console.log("getSubuserCompanies error ", err);
                const handle = () => {
                  navigate("/login", { state: { info: mainErrorMessage } });
                };
                signOut(auth).then(handle).catch(handle);
                return;
              }

              // Sets subusers companies
              console.log("Companies ", companies);
              setSubuserCompanies([...companies]);
              setAuthLoading(false);
            }
            // Not subuser - sets everything as ready
            else {
              setControlUid(authUser.uid);
              setAuthLoading(false);
            }
          });
        }
        // There is no auth user
        else {
          console.log("No auth user");
          setUserState(null);
          setClaims(null);
          deleteControlUid();
          setAuthLoading(false);
        }
      });

      return () => {
        unsubscribe();
      };
    }, []);

    function forceRefresh(newUrl, urlState = {}) {
      console.log("Claims force refresh trigger");
      console.log("Auth current user ", auth?.currentUser);

      if (!auth?.currentUser) {
        navigate("/login");
        return;
      }

      auth.currentUser
        .getIdTokenResult(true)
        .then(function (idTokenResult) {
          console.log("New claims ", idTokenResult);
          setClaims(idTokenResult.claims);
          if (newUrl) {
            navigate(newUrl, urlState);
          }
        })
        .catch(function (error) {
          signOut(auth);
        });
    }

    if (authLoading) return <LoadingBackdrop open={true} />;

    // Propagates auth props
    return (
      <ClaimsContext.Provider
        value={{
          ...claims,
          subuserCompanies,
          setSubuserCompanies,
          setClaims,
          forceRefresh,
          getPermissions,
        }}
      >
        <Component
          userState={userState}
          claims={claims}
          setClaims={setClaims}
          subuserCompanies={subuserCompanies}
          setSubuserCompanies={setSubuserCompanies}
          authLoading={authLoading}
          getPermissions={getPermissions}
          {...props}
        />
      </ClaimsContext.Provider>
    );
  };

  return Wrapper;
};
