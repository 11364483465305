import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {
  appGreen,
  appRedLight,
  baseBorder,
  dividerGrey,
  navbarBlue,
  placeholderGrey,
} from "../../../../Design/SourcesJS/themes";
import ActionMenu from "../../Menu/ActionMenu";
import IconButton from "../../Buttons/IconButton";
import DeleteForeverRounded from "@mui/icons-material/DeleteForeverRounded";
import EditRounded from "@mui/icons-material/EditRounded";
import { useNavigate } from "react-router-dom";
import { getNumberVisual } from "./FakturaAddTable/Calculations/FakturaCalcLayout";
import { currenciesList } from "../../../Data/currencies";
import isValidDate, { isSameDay } from "../../../../Classes/isValidDate";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import { withRouter } from "../../../../Routes/withRouter";
import Tooltip from "../../Base/Tooltip";
import { theme } from "../../../../../App/App";

const DoubleLineInvoice = React.lazy(() =>
  import("../MobileTables/DoubleLineInvoice")
);

const SCREEN_SIZE = 25;
const MIN_WIDTH = { xs: "740px", sm: "756px", md: "756px" };

const centerGridContent = {
  align: "center",
  justify: "center",
  alignItems: "center",
};

const tableScrollbarBottom = {
  borderBottomLeftRadius: { xs: "6px", sm: "6px", md: "12px" },
  borderBottomRightRadius: { xs: "6px", sm: "6px", md: "12px" },
  "&::-webkit-scrollbar": {
    display: "block",
    height: "6px",
    scrollbarGutter: "stable both-edges",
  },
  "&::-webkit-scrollbar-track": {
    display: "block",
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    scrollbarGutter: "stable both-edges",
  },
  "&::-webkit-scrollbar-thumb": {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
    scrollbarGutter: "stable both-edges",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#ccc #f1f1f1",
};

function roundToTwo(num) {
  return +(Math.round(parseFloat(num) + Number.EPSILON + "e+2") + "e-2");
}

export function dateToVisual(oldDate) {
  if (!oldDate) return "";
  try {
    let date = new Date(oldDate);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  } catch (err) {
    return oldDate;
  }
}

export function timestampToDate(timestamp) {
  try {
    if (isValidDate(timestamp)) {
      return timestamp;
    }
    let date = new Date(timestamp.toDate());
    if (!isValidDate(date)) {
      throw new Error("Invalid Date");
    }
    return date;
  } catch (err) {
    let seconds = timestamp?.seconds || timestamp?._seconds;
    if (seconds) {
      let date = new Date(seconds * 1000);
      return date;
    } else {
      return null;
    }
  }
}

function isFirebaseTimestamp(value) {
  // Check if the value is an object
  if (typeof value === "object" && value !== null) {
    // Check if the object has the expected properties of a Firebase Timestamp
    return (
      value.hasOwnProperty("_seconds") &&
      value.hasOwnProperty("_nanoseconds") &&
      typeof value._seconds === "number" &&
      typeof value._nanoseconds === "number"
    );
  }

  return false;
}

export function timestampToDateVisual(timestamp) {
  if (!timestamp) return "-";
  try {
    if (isFirebaseTimestamp(timestamp)) {
      return dateToVisual(timestampToDate(timestamp));
    } else if (isValidDate(new Date(timestamp))) return dateToVisual(timestamp);
  } catch (err) {}
  return dateToVisual(timestampToDate(timestamp));
}

export function getIsoFromIndex(index) {
  return currenciesList[index].iso;
}

export function getIndexFromIso(iso) {
  let finalIso = iso || "CZK";
  var elementPos = currenciesList
    .map(function (x) {
      return x.iso;
    })
    .indexOf(finalIso);
  if (elementPos < 0) return 0;
  return elementPos;
}

function getLeftBorder(data) {
  if (data.cxDate) return `4px solid ${placeholderGrey}`;
  let dueDate = timestampToDate(data.dueDate);
  let dateNow = new Date();

  if (data.payDate) {
    return `4px solid ${appGreen}`; //false;
  }

  if (dueDate < dateNow && !isSameDay(dueDate, dateNow)) {
    return `4px solid ${appRedLight}`;
  }
  return false;
}

function getLeftColor(data) {
  if (data.cxDate) return placeholderGrey;
  let dueDate = timestampToDate(data.dueDate);
  let dateNow = new Date();

  if (data.payDate) {
    return appGreen;
  }

  if (dueDate < dateNow && !isSameDay(dueDate, dateNow)) {
    return appRedLight;
  }
  return "transparent";
}

function FakturaTable(props) {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const navigate = useNavigate();
  let {
    dataList = [],
    isPlatce,
    sxPropx = {},
    onDeleteItem,
    onPayButtonClick,
    removeKontaktIcon = false,
    page = 1,
    level = 0,
  } = props;

  function getCostVisual(cost, iso = null) {
    let finalCost = roundToTwo(cost);
    if (!finalCost) return "-";
    if (finalCost.toString().length > 10) {
      return `${finalCost.toExponential(2)} Kč`;
    }
    return `${getNumberVisual(finalCost, getIndexFromIso(iso))}`;
  }

  const tableRowStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderRight: `1px solid ${dividerGrey}`,
    borderTop: `1px solid ${dividerGrey}`,
  };

  const paddingLeft = { xs: "15px", sm: "20px", md: "35px", lg: "45px" };

  const textStyle = {
    paddingLeft: { xs: "15px", sm: "20px", md: "35px", lg: "45px" },
    //paddingRight: "3px",
  };

  function onClickGetIndex(buttonIndex, lineIndex) {
    const realIndex = (page - 1) * 10 + lineIndex;

    switch (buttonIndex) {
      case 0:
        onDeleteItem(
          dataList[lineIndex].fakturaUid,
          dataList[lineIndex].listUid
        );
        return;
      case 1:
        navigate("/faktura/uprava", {
          state: {
            data: {
              control: {
                fakturaUid: dataList[lineIndex].fakturaUid,
                listUid: dataList[lineIndex].listUid,
              },
            },
          },
        });
        return;
      case 2:
        onPayButtonClick(dataList[lineIndex], realIndex);
        return;
      default:
        return;
    }
  }

  if (mobile) {
    return (
      <>
        {dataList.map((line, index) => (
          <Box
            sx={{ mt: index !== 0 ? "14px" : "0px" }}
            key={"FakTablePhone" + index}
          >
            <DoubleLineInvoice
              tooltip={
                line.payDate ? (
                  <span style={{ fontWeight: "normal" }}>
                    <b>Datum splatnosti</b>
                    <br />
                    {timestampToDateVisual(line.dueDate)}
                    <br />
                    <b>Datum úhrady</b>
                    <br />
                    {timestampToDateVisual(line.payDate)}
                  </span>
                ) : (
                  <span style={{ fontWeight: "normal" }}>
                    <b>Datum splatnosti</b>
                    <br />
                    {timestampToDateVisual(line.dueDate)}
                  </span>
                )
              }
              title={`Faktura č. ${line.inv}`}
              text={line.name}
              subText={
                line.tax
                  ? `${getCostVisual(
                      line.tax ? line.price + line.tax : line.price,
                      line.iso
                    )} vč. DPH`
                  : getCostVisual(line.price, line.iso)
              }
              actionList={[
                {
                  text: "Odstranit",
                  icon: <DeleteForeverRounded />,
                  hide: Boolean(level == 0),
                },
                { text: "Upravit", icon: <EditRounded /> },
                {
                  text: dataList[index]?.payDate
                    ? "Změnit úhradu"
                    : "Zaznamenat úhradu",
                  icon: <CreditScoreRoundedIcon />,
                },
              ]}
              onClickGetIndex={onClickGetIndex}
              onClick={() => {
                navigate("/faktura/detail", {
                  state: { fakturaUid: dataList[index].fakturaUid },
                });
              }}
              index={index}
              onPersonClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/adresar/detail?uid=${line.customer}`);
              }}
              leftColor={getLeftColor(line)}
            />
          </Box>
        ))}
      </>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      columns={SCREEN_SIZE}
      {...centerGridContent}
      sx={{
        display: "flex",
        position: "relative",
        width: "100%",
        overflowX: { xs: "auto", sm: "auto", md: "hidden" },
        overflowY: "hidden",
        //spacing: 0,
        //justify: "space-around",
        justifyContent: "flex-start",
        mt: "70px",
        boxShadow: "1px 2px 5px 0px rgba(114,117,122,0.2)",
        borderRadius: "12px",
        border: baseBorder,
        backgroundColor: "#FFF", //"#F2F1F1",
        pt: "32px",
        ...tableScrollbarBottom,
        ...sxPropx,
      }}
    >
      <Grid
        container
        item
        columns={SCREEN_SIZE}
        sx={{
          mb: "32px",
          minWidth: MIN_WIDTH,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={4} sm={4} sx={{}}>
          <Typography variant="tableHeader" sx={{ ...textStyle }}>
            Faktura
          </Typography>
        </Grid>
        <Grid
          item
          xs={isPlatce == 1 ? 8 : 12}
          sm={isPlatce == 1 ? 8 : 12}
          sx={{}}
        >
          <Typography variant="tableHeader" sx={{ ...textStyle }}>
            Odběratel
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} sx={{}}>
          <Typography variant="tableHeader" sx={{ ...textStyle }}>
            {isPlatce == 1 ? "Cena bez DPH" : "Cena"}
          </Typography>
        </Grid>
        {isPlatce == 1 && (
          <Grid item xs={4} sm={4} sx={{}}>
            <Typography variant="tableHeader" sx={{ ...textStyle }}>
              Cena vč. DPH
            </Typography>
          </Grid>
        )}

        <Grid item xs={3} sm={3} sx={{}}>
          <Typography variant="tableHeader" sx={{ textAlign: "center" }}>
            Splatnost
          </Typography>
        </Grid>

        <Grid item xs={2} sm={2} sx={{}}>
          <Typography variant="tableHeader" sx={{ textAlign: "center" }}>
            Akce
          </Typography>
        </Grid>
      </Grid>
      {dataList.map((line, index) => (
        <Grid
          key={"FakturaTableLine" + index}
          container
          alignItems="center"
          justifyContent="center"
          item
          columns={SCREEN_SIZE}
          sx={{
            position: "relative",
            height: "48px",
            overflow: "visible",
            minWidth: MIN_WIDTH,
            width: "100%",
            borderBottomLeftRadius:
              index == dataList.length - 1
                ? { xs: "0px", sm: "0px", md: "12px" }
                : "0px",
            "&:hover": {
              borderRight: `4px solid ${navbarBlue}`,
              cursor: "pointer",
              borderBottomLeftRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
              borderBottomRightRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
            },
            borderBottom:
              index === dataList.length - 1
                ? { xs: `1px solid ${dividerGrey}`, sm: 0 }
                : 0,
          }}
          onClick={() => {
            navigate("/faktura/detail", {
              state: { fakturaUid: dataList[index].fakturaUid },
            });
          }}
        >
          <Grid
            item
            xs={4}
            sm={4}
            sx={{
              ...tableRowStyle,
              borderLeft: getLeftBorder(line) || "none",
              borderBottomLeftRadius:
                index == dataList.length - 1
                  ? { xs: "0px", sm: "0px", md: "12px" }
                  : "0px",
            }}
          >
            <Typography
              variant="tableText"
              sx={{
                ...textStyle,
                mr: "8px",
                //ml: !getLeftBorder(line) ? "0px" : "-4px",
              }}
            >
              {line.inv}
            </Typography>
          </Grid>
          <Grid
            item
            xs={isPlatce == 1 ? 8 : 12}
            sm={isPlatce == 1 ? 8 : 12}
            sx={{ ...tableRowStyle }}
          >
            {!removeKontaktIcon && (
              <Box
                sx={{
                  pl: { xs: "15px", sm: "20px", md: "35px", lg: "45px" },
                  pr: "8px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <PersonSearchRoundedIcon
                  sx={{
                    fill: placeholderGrey,
                    height: "28px",
                    width: "28px",
                    "&:hover": {
                      fill: navbarBlue,
                      cursor: "pointer",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/adresar/detail?uid=${line.customer}`);
                  }}
                />
              </Box>
            )}
            <Typography
              variant="tableText"
              sx={{
                ...textStyle,
                paddingLeft: removeKontaktIcon ? paddingLeft : "0px",
              }}
            >
              {line.name}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} sx={{ ...tableRowStyle }}>
            <Typography variant="tableText" sx={{ ...textStyle }}>
              {isPlatce == 1
                ? getCostVisual(line.price, line.iso)
                : getCostVisual(
                    line.tax ? line.price + line.tax : line.price,
                    line.iso
                  )}
            </Typography>
          </Grid>
          {isPlatce == 1 && (
            <Grid item xs={4} sm={4} sx={{ ...tableRowStyle }}>
              <Typography variant="tableText" sx={{ ...textStyle }}>
                {line.tax
                  ? getCostVisual(
                      line.tax ? line.price + line.tax : line.price,
                      line.iso
                    )
                  : "–"}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={3}
            sm={3}
            sx={{ ...tableRowStyle, justifyContent: "center" }}
          >
            <Tooltip
              text={
                line.payDate ? (
                  <span style={{ fontWeight: "normal" }}>
                    <b>Datum splatnosti</b>
                    <br />
                    {timestampToDateVisual(line.dueDate)}
                    <br />
                    <b>Datum úhrady</b>
                    <br />
                    {timestampToDateVisual(line.payDate)}
                  </span>
                ) : undefined
              }
            >
              <Typography variant="tableText" sx={{ textAlign: "center" }}>
                {line.payDate
                  ? "uhrazeno"
                  : timestampToDateVisual(line.dueDate)}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={2}
            sm={2}
            sx={{
              ...tableRowStyle,
              borderRight: 0,
              display: "flex",
              justifyContent: "center",
              pr: "0px",
              //"&:hover": {},
            }}
            /*onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}*/
          >
            <ActionMenu
              list={[
                {
                  text: "Odstranit",
                  icon: <DeleteForeverRounded />,
                  hide: Boolean(level == 0),
                },
                { text: "Upravit", icon: <EditRounded /> },
                {
                  text: dataList[index]?.payDate
                    ? "Změnit úhradu"
                    : "Zaznamenat úhradu",
                  icon: <CreditScoreRoundedIcon />,
                },
              ]}
              onClickGetIndex={onClickGetIndex}
              index={index}
              sxBox={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <IconButton
                dontHover={false}
                sxProps={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0",
                  ml: "6px",
                }}
                scale={1.1}
                icon={
                  <MoreHorizRoundedIcon
                    sx={{ fill: navbarBlue, width: "30px", height: "30px" }}
                  />
                }
              />
            </ActionMenu>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default withRouter(FakturaTable);
