import { useContext } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ClaimsContext, useClaimsContext } from "../../App/withAuth";
import { getControlUidNullable } from "../Core/Pages/Settings/SubscriptionPage/SubUsers/Access";
import {
  getShowAd,
  locStorAvaiable,
} from "../Core/Components/Ads/adLocalStorage";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const claims = useClaimsContext();
    const { ...pathParams } = useParams();

    const isFreeAccount = !claims?.subuser && claims?.level === 0;
    const isSubuser = claims?.subuser;
    const subuserCompanies = claims?.subuserCompanies || [];

    function getPlatce() {
      try {
        if (isSubuser) {
          return getCompanyDic();
        }
        let dic = parseInt(claims?.dic);
        if (!dic || isNaN(dic)) {
          return 0;
        }
        return dic;
      } catch (error) {
        return 0;
      }
    }

    function getLevel() {
      if (isSubuser) {
        return getCompanyLevel();
      }
      return claims?.level || 0;
    }

    function getCompanyDic() {
      const uid = getControlUidNullable();
      for (let i = 0; i < subuserCompanies.length; i++) {
        if (subuserCompanies[i].uid === uid) return subuserCompanies[i].dic;
      }
      return 0;
    }

    function getCompanyLevel() {
      const uid = getControlUidNullable();
      for (let i = 0; i < subuserCompanies.length; i++) {
        if (subuserCompanies[i].uid === uid) return subuserCompanies[i].level;
      }
      return 0;
    }

    function hasPermission(permission) {
      if (!claims.subuser) return true;
      return claims.getPermissions()?.[permission] === true;
    }

    function showAdFree(adId, customCondition) {
      if (isSubuser) return false;
      if (
        isFreeAccount &&
        (getShowAd(adId) || (!locStorAvaiable() && customCondition))
      )
        return true;
      return false;
    }

    return (
      <Component
        location={location}
        navigate={navigate}
        /*
        0 - ne
        1 - ano
        2 - ident. osoba
        */
        claims={claims}
        dic={getPlatce()}
        level={getLevel()}
        isSubuser={isSubuser}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        hasPermission={hasPermission}
        // Ads
        isFreeAccount={isFreeAccount}
        showAdFree={showAdFree}
        pathParams={pathParams}
        {...props}
      />
    );
  };

  return Wrapper;
};
