import { useSalesContext } from "./SalesContext";
import { getDateFromString } from "../Classes/datesControl";

export const withSalesContext = (Component) => {
  const Wrapper = (props) => {
    const sales = useSalesContext();

    const isThereValidSale = () => {
      return Boolean(sales?.saleData && sales?.saleData?.a);
    };

    const getSaleEndTime = () => {
      try {
        if (!sales?.saleData?.ed) {
          return null;
        }
        return getDateFromString(sales?.saleData?.ed);
      } catch (error) {
        return null;
      }
    };

    const getSaleAmount = () => {
      const plans = sales?.saleData?.a;
      if (!plans) return 0;
      if (typeof plans === "number") return plans;
      let amounts = [];
      for (let i = 0; i < plans.length; i++) {
        const vals = plans[i];
        for (let j = 0; j < vals.length; j++) {
          amounts.push(vals[j]);
        }
      }

      let uniqueArray = [...new Set(amounts)].filter((number) => number != 0);
      try {
        return parseInt(uniqueArray[0]);
      } catch (error) {
        console.log(error);
        return 0;
      }
    };

    return (
      <Component
        sales={sales}
        isThereValidSale={isThereValidSale}
        getSaleAmount={getSaleAmount}
        getSaleEndTime={getSaleEndTime}
        isSalePanel={isThereValidSale() && getSaleAmount() != 0}
        {...props}
      />
    );
  };

  return Wrapper;
};
