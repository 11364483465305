// CURRENT URL - location.pathname ( viz.: /settings )

import isEqual from "lodash.isequal";

export function navigateRedirect(
  navigate,
  newUrl,
  data /* / STATE / VALUES */
) {
  const originalUrl = window.location.pathname;
  console.log(originalUrl);
  navigate(newUrl, { state: { originalUrl, newUrl, data } });
}

export function isRedirectState(location) {
  return (
    location?.state?.originalUrl &&
    location?.state?.newUrl &&
    location?.state?.data
  );
}

export function isBackRedirect(location) {
  return location?.state?.originalUrl === location.pathname;
}

export function isLocationStateUrl(locationState, location) {
  if (
    locationState?.originalUrl &&
    locationState?.newUrl &&
    locationState?.data
  ) {
    if (
      location.pathname !== locationState.originalUrl &&
      location.pathname !== locationState.newUrl
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function isLocationsEqual(locationState, location) {
  return isEqual(locationState.data, location.state.data);
}

export function shouldSaveState(locationState, location) {
  if (
    isRedirectState(location) &&
    !isBackRedirect(location) &&
    (!locationState || !isLocationsEqual(locationState, location))
  ) {
    return true;
  }
  return false;
}

export function isAnyRedirectUrl(location) {
  if (
    location?.state?.originalUrl &&
    location?.state?.newUrl &&
    location?.state?.data
  ) {
    if (
      location.pathname !== location.state.originalUrl &&
      location.pathname !== location.state.newUrl
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

// ORIGINAL URL - GET DATA
export function isOriginalUrl(location) {
  if (
    location?.state?.originalUrl &&
    location?.state?.newUrl &&
    location?.state?.data
  ) {
    if (location.pathname === location?.state?.originalUrl) {
      return true;
    }
  }
  return false;
}

export function getOriginalData(location) {
  return location?.state?.data;
}

// NEW URL - REDIRECT BACK TO ORIGINAL
export function isNewUrl(location) {
  if (
    location?.state?.originalUrl &&
    location?.state?.newUrl &&
    location?.state?.data
  ) {
    if (location.pathname === location?.state?.newUrl) {
      return true;
    }
  }
  return false;
}

export function navigateBack(navigate, location, additionalData = {}) {
  navigate(location.state.originalUrl, {
    state: { ...location.state, ...(additionalData && { ...additionalData }) },
  });
}

export function getOriginalState(location) {
  return location?.state;
}
