import { isDateOlderThanDays } from "../../Resources/Classes/datesControl";
import isValidDate from "../../Resources/Classes/isValidDate";
import { auth } from "../../Resources/Firebase/init";
import { PageWrapper } from "./PrivateRoute";
import PermissionComp from "./PermissionComp";
import { lazy, memo, useEffect } from "react";
import isEqual from "lodash.isequal";

const Navbar = lazy(() =>
  import("../../Resources/Core/Components/Navbars/Navbar")
);
const BaseFooter = lazy(() =>
  import("../../Resources/Core/Components/Footer/BaseFooter")
);
const LoginNavbar = lazy(() =>
  import("../../Resources/Core/Components/Navbars/UserNavbars/LoginNavbar")
);
const UpgradeBar = lazy(() =>
  import("../../Resources/Core/Components/Ads/UpgradeBar/UpgradeBar")
);
const SecondaryNavbar = lazy(() =>
  import("../../Resources/Core/Components/Navbars/Secondary/SecondaryNavbar")
);

const tutorialCompleted = (userState, claims) => {
  if (claims?.subuser) return true;
  return userState && userState.emailVerified && !claims?.tutorial;
};

const showAdBar = (isValidSale, userState, claims) => {
  if (
    !Boolean(userState) ||
    !claims ||
    Object.keys(claims).length === 0 ||
    claims?.level != 0
  ) {
    return false;
  }
  if (!tutorialCompleted(userState, claims) || claims?.subuser) return false;
  const date = new Date(auth?.currentUser?.metadata?.creationTime);
  if (isValidSale) return true;
  if (!isValidDate(date)) return false;
  if (!isValidSale && window.location.pathname === "/plany") return false;
  return isDateOlderThanDays(date, 180);
};

const PageContainerWrap = ({ route, ...props }) => {
  const { isThereValidSale, userState, claims } = props;

  useEffect(() => {
    console.log("PageContainerWrap reload");
  });

  return (
    <PageWrapper>
      <div
        id="floatContainer"
        style={{
          minHeight: "100vh",
          position: "relative",
        }}
      >
        {!route.noNavbar && !route.public && (
          <Navbar currentPath={route.path} isPublic={route.public} />
        )}
        {route.public && !route.noNavbar && <LoginNavbar />}
        {!route.noSecNavbar && <SecondaryNavbar />}
        <PermissionComp element={route.element} />
      </div>
      {showAdBar(
        isThereValidSale ? isThereValidSale() : false,
        userState,
        claims
      ) &&
        !route.public &&
        !route.hideAd && <UpgradeBar {...props} />}
      {!route.noFooter && <BaseFooter path={route.path} />}
    </PageWrapper>
  );
};

// arePropsEqual
export default memo(PageContainerWrap, (prevProps, nextProps) => {
  const { ...prevCheck } = prevProps;
  const { ...nextCheck } = nextProps;

  return isEqual(prevCheck, nextCheck);
});
