import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  appDarkBlue,
  appRed,
  navbarBlue,
  textGrey,
  zindex,
} from "../../../../Design/SourcesJS/themes";
import BaseButton from "../../Buttons/BaseButton";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Motion } from "../../Motion/Motion";
import { ReactComponent as RedFooter } from "./red_footer.svg";
import { ReactComponent as BlueFooter } from "./blue_footer.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";

const SvgBackground = ({ svg }) => {
  const editedIcon = React.cloneElement(svg, {
    style: {
      height: "auto",
      width: "100%",
      minWidth: "440px",
    },
  });
  return <>{editedIcon}</>;
};

export default function GraphicDialog({
  open = false,
  handleClose = () => {},
  data,
}) {
  const navigate = useNavigate();
  const imageFits = !useMediaQuery("(max-height:620px)");

  if (!data) return <></>;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: zindex.maxOverlay,
        minHeight: "100%",
        ".MuiPaper-root": {
          borderRadius: { xs: "0px", sm: "10px" },
          minWidth: "310px",
          width: { xs: "100%", sm: "580px" },
          maxWidth: { xs: "100%", sm: "580px" },
          minHeight: { xs: "100%", sm: "160px" },
          height: { xs: "100%", sm: "auto" },
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          overflowY: { xs: "scroll", sm: "hidden" },
          overflowX: "hidden",
          margin: { xs: "0px", sm: "26px", md: "32px" },
        },
      }}
    >
      <Motion>
        <Box
          sx={{
            p: "10px",
            cursor: "pointer",
            position: "absolute",
            top: 5,
            right: 5,
            height: "40px",
            width: "40px",
          }}
          onClick={handleClose}
        >
          <CloseRoundedIcon
            sx={{ fill: textGrey, height: "20px", width: "20px" }}
          />
        </Box>
      </Motion>
      <DialogContent
        sx={{
          width: "100%",
          pt: "80px",
          px: { xs: "40px", sm: "80px", md: "80px" },
          pb: "0px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: "320px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            fontSize: "17px",
            color: appDarkBlue,
            fontWeight: "bold",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignContent: "flex-start",
            textAlign: "left",
            p: "0px",
            height: { xs: "fit-content", sm: "auto" },
          }}
          id="alert-dialog-description"
        >
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                alignContent: "flex-end",
                overflow: "hidden",
              }}
            >
              <Typography
                component="div"
                sx={[
                  {
                    color: data.blue ? navbarBlue : appRed,
                    textAlign: "left",
                    width: "100%",
                    fontSize: { xs: "13px", sm: "15px" },
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    lineClamp: 1,
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    MozOrient: "vertical",
                    fontFamily: "Gordita",
                    fontWeight: "bold",
                    lineHeight: "normal",
                  },
                ]}
              >
                {data?.smallTitle || ""}
              </Typography>
            </Box>
          </Box>
          <Typography
            component="h2"
            sx={[
              {
                color: appDarkBlue,
                fontFamily: "Gordita",
                fontWeight: "bold",
                textAlign: "left",
                mb: "15px",
                mt: "15px",
                fontSize: { xs: "22px", sm: "25px" },
                lineHeight: { xs: "30px", sm: "31px" },
              },
            ]}
          >
            {data?.title || ""}
          </Typography>
          <Typography
            sx={[
              {
                color: textGrey,
                fontSize: "13px",
                lineHeight: "21px",
                fontWeight: 500,
                textAlign: "left",
              },
            ]}
          >
            {data?.text || ""}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: imageFits ? { xs: "auto" } : "auto",
            mt: imageFits ? "0px" : "auto",
            pt: imageFits ? { xs: "100px", sm: "0px" } : "auto",
          }}
        >
          <BaseButton
            text={data?.buttonText || "Potvrdit"}
            onClick={(e) => {
              if (data.onButtonClick) {
                data.onButtonClick();
                return;
              }
              if (data.buttonNavigate) {
                if (data.fullUrl) {
                  window.open(data.buttonNavigate, "_blank");
                } else {
                  navigate(data.buttonNavigate);
                }
                return;
              }
              handleClose();
            }}
            sxProps={{
              mx: "20px",
              textAlign: "center",
              mb: imageFits ? "0px" : "20px",
              mt: { xs: "38px", sm: "26px" },
              maxWidth: "200px",
              bgcolor: navbarBlue,
            }}
            sxText={{
              fontSize: "15px",
              lineHeight: "16px",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }}
          />
        </Box>
        {data.smallButtonText && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Motion>
              <Typography
                variant="text"
                sx={{
                  color: appRed,
                  fontSize: "15px",
                  fontWeight: "bold",
                  mt: "8px",
                  p: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (data.onSmallButtonClick) {
                    data.onSmallButtonClick();
                  }
                }}
              >
                {data.smallButtonText}
              </Typography>
            </Motion>
          </Box>
        )}
      </DialogContent>
      {data.svg && imageFits && (
        <Box
          sx={{
            bgcolor: appDarkBlue,
            position: "absolute",
            bottom: 0,
            height: "20px",
            width: "100%",
          }}
        />
      )}
      {data.svg && (
        <Box
          sx={{
            width: "101%",
            height: "auto",
            display: "flex",
            position: "relative",
            justifyContent: "center",
          }}
        >
          {imageFits && (
            <SvgBackground svg={data.blue ? <BlueFooter /> : <RedFooter />} />
          )}
          {data.bottomText && imageFits && (
            <Typography
              variant="text"
              sx={{
                color: "white",
                fontSize: "12px",
                position: "absolute",
                textAlign: "center",
                ml: "auto",
                mr: "auto",
                maxWidth: "340px",
                bottom: { xs: 12, sm: 14 },
              }}
            >
              {data.bottomText}
            </Typography>
          )}
        </Box>
      )}
    </Dialog>
  );
}
