import React, { lazy, useState } from "react";
import { withAdDialog } from "../../Resources/Core/Components/Ads/LightBox/withAdDialog";
import { withRouter } from "../../Resources/Routes/withRouter";

const PermissionDeniedPage = lazy(() =>
  import(
    "../../Resources/Core/Pages/Settings/SubscriptionPage/SubUsers/PermissionDenied/PermissionDeniedPage"
  )
);

const ClonedElement = (reProps) => {
  const { element, ...otherProps } = reProps;
  const newElement = React.cloneElement(element, otherProps);
  return newElement;
};

const PermissionComp = ({ element }) => {
  const [denied, setDenied] = useState(false);

  if (denied) return <PermissionDeniedPage noNavbar={true} />;
  const RouterElement = withAdDialog(
    withRouter((reProps) => (
      <ClonedElement element={element} setDenied={setDenied} {...reProps} />
    ))
  );

  return <RouterElement />;
};

export default PermissionComp;
