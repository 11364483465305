import React, { lazy } from "react";

const NewSubuser = lazy(() =>
  import("../../Core/Pages/Settings/SubscriptionPage/SubUsers/NewSubuser")
);

const BankaAdd = lazy(() => import("../../Core/Pages/Settings/Banka/BankaAdd"));
const BankaEdit = lazy(() =>
  import("../../Core/Pages/Settings/Banka/BankaEdit")
);
const BankaList = lazy(() =>
  import("../../Core/Pages/Settings/Banka/BankaList")
);

const CislovaniFaktur = lazy(() =>
  import("../../Core/Pages/Settings/Cislovani/CislovaniFaktur")
);
const Fakturace = lazy(() => import("../../Core/Pages/Settings/Fakturace"));
const SettingsPage = lazy(() =>
  import("../../Core/Pages/Settings/SettingsPage")
);
const MojeVychoziUdaje = lazy(() =>
  import("../../Core/Pages/Settings/MojeVychoziUdaje")
);
const FakturaVzhled = lazy(() =>
  import("../../Core/Pages/Settings/VzhledFaktur/FakturaVzhled")
);
const SubscriptionPage = lazy(() =>
  import(
    "../../Core/Pages/Settings/SubscriptionPage/Subscription/SubscriptionPage"
  )
);

const settingsRoutes = [
  {
    path: "/settings",
    element: <SettingsPage />,
    permission: "eSet",
  },
  {
    path: "/settings/cislovani",
    element: <CislovaniFaktur />,
    permission: "eSet",
  },
  {
    path: "/settings/zalohovky",
    element: <CislovaniFaktur zalohovky={true} />,
    permission: "eSet",
  },
  {
    path: "/settings/vzhled",
    element: <FakturaVzhled />,
    permission: "eSet",
  },
  {
    path: "/settings/fakturace",
    element: <Fakturace />,
    permission: "eSet",
  },
  {
    path: "/settings/vychozi",
    element: <MojeVychoziUdaje />,
    permission: "eSet",
  },
  {
    path: "/settings/banka/pridat",
    element: <BankaAdd />,
    permission: "eSet",
  },
  {
    path: "/settings/banka/list",
    element: <BankaList />,
    permission: "eSet",
  },
  {
    path: "/settings/banka/upravit",
    element: <BankaEdit />,
    permission: "eSet",
  },
  // Subscriptions, Subusers
  {
    path: "/subscription",
    element: <SubscriptionPage />,
    permission: "null",
  },
  {
    path: "/subscription/subuser",
    element: <NewSubuser />,
    permission: "null",
  },
];

export default settingsRoutes;
