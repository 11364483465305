import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as LogoSvg } from "../../../Design/Graphics/svg/LogoSlon.svg";
import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { withRouter } from "../../../Routes/withRouter";
import {
  appDarkBlue,
  appOrange,
  navbarBlue,
  zindex,
} from "../../../Design/SourcesJS/themes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mainNavigation } from "../../../Routes/Core/navigation";

import { theme } from "../../../../App/App";
import { ReactComponent as UpgradeSvg } from "../../../Design/Graphics/svg/Ads/Upgrade.svg";
import { Motion } from "../Motion/Motion";
import isEqual from "lodash.isequal";
import InfoDialog from "../Feedback/InfoDialog/InfoDialog";
import LoadingBackdrop from "../Layouts/LoadingBackdrop";

const SupportDialog = React.lazy(() =>
  import("../SupportDialog/SupportDialog")
);
const MobileDrawerMenu = React.lazy(() => import("../Menu/MobileDrawerMenu"));
const ProfileMenu = React.lazy(() => import("../Menu/ProfileMenu"));
const TabletMenu = React.lazy(() => import("../Menu/TabletMenu"));

const height = "45px";

const Icon = ({ icon, sx = {} }) => {
  const editedIcon = React.cloneElement(icon, {
    style: {
      height: "100%",
      maxWidth: "33px",
      fill: navbarBlue,
      ...sx,
    },
  });
  return <>{editedIcon}</>;
};

function Navbar({ currentPath, isFreeAccount }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [hovered, setHovered] = React.useState(null);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [suppDialog, setSuppDialog] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <header>
      <LoadingBackdrop open={loading} />
      <InfoDialog
        handleClose={() => {
          setDialog(null);
        }}
        dialog={dialog}
      />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            maxHeight: height,
            height: height,
            backgroundColor: appDarkBlue,
            ".MuiToolbar-root": {
              minHeight: height,
              maxHeight: height,
              height: height,
              px: "16px",
              maxWidth: 1200,
              width: "100%",
              mx: "auto",
              zIndex: zindex.navbar,
              backgroundColor: appDarkBlue,
            },
          }}
        >
          <Toolbar>
            <Box
              component={Link}
              sx={{
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
                p: "5px",
                pl: "0px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              to={"/faktura/list"}
            >
              <Icon icon={<LogoSvg />} />
              <Typography
                variant="logo"
                sx={{
                  ml: "8px",
                  height: "100%",
                }}
              >
                profaktura
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {md ? (
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  {mainNavigation.map((data, index) => (
                    <Button
                      key={"ButtonNavbar" + index}
                      id={"ButtonNavbarId" + index}
                      name={`ButtonNavbarN${index}`}
                      sx={{ mr: "8px" /*minWidth: 0*/ }}
                      component={Link}
                      to={mainNavigation[index].path}
                      tabIndex={index}
                      onClick={(e) => {
                        e.preventDefault();
                        if (data.path != currentPath) {
                          if (e.target.name == hovered) {
                            setHovered(null);
                          }
                          navigate(data.path);
                        }
                      }}
                      onMouseEnter={(e) => {
                        setHovered(e.target.name);
                      }}
                      onMouseLeave={(e) => {
                        if (e.target.name == hovered) {
                          setHovered(null);
                        }
                      }}
                      onContextMenu={(e) => {
                        setHovered(null);
                      }}
                    >
                      <Typography
                        variant="navbar"
                        sx={{
                          transform:
                            hovered == `ButtonNavbarN${index}`
                              ? "translateY(-2px)"
                              : "translateY(0px)",
                          transitionDuration: "200ms",
                        }}
                      >
                        {data.name}
                      </Typography>
                    </Button>
                  ))}
                  {isFreeAccount && (
                    <Button
                      key={"ButtonUpgradeNavbar"}
                      id={"ButtonNavbarUpgradeId"}
                      name={`ButtonNavbarUpgrade`}
                      sx={{
                        mr: "1px",
                        bgcolor: `${appOrange} !important`,
                        borderRadius: "40px",
                        transform: "scale(1)",
                        transition: "transform .3s ease",
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor: `${appOrange} !important`,
                          transform: "scale(1.1)",
                          transition: "transform .3s ease",
                        },
                        height: "24px",
                      }}
                      onClick={(e) => {
                        if (location.pathname === "/plany") return;
                        e.preventDefault();
                        navigate("/plany");
                      }}
                    >
                      <Typography variant="navbar" sx={{}}>
                        {`Upgradovat`}
                      </Typography>
                    </Button>
                  )}
                </Box>
              ) : (
                <>
                  {isFreeAccount && (
                    <Motion>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          p: "5px",
                          mr: "4px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          if (location.pathname === "/plany") return;
                          e.preventDefault();
                          navigate("/plany");
                        }}
                      >
                        <Icon
                          icon={<UpgradeSvg />}
                          sx={{ width: "25px", height: "25px" }}
                        />
                      </Box>
                    </Motion>
                  )}
                </>
              )}
              {md && (
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "row",
                  }}
                >
                  <ProfileMenu
                    suppDialog={suppDialog}
                    setSuppDialog={setSuppDialog}
                  >
                    <AccountCircleIcon
                      style={{ fill: "white", height: "28px", width: "28px" }}
                    />
                  </ProfileMenu>
                </Box>
              )}
              {sm && (
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex", md: "none" },
                    flexDirection: "row",
                  }}
                >
                  <TabletMenu
                    suppDialog={suppDialog}
                    setSuppDialog={setSuppDialog}
                  />
                </Box>
              )}
              {xs && (
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none", md: "none" },
                    flexDirection: "row",
                  }}
                >
                  <MobileDrawerMenu
                    suppDialog={suppDialog}
                    setSuppDialog={setSuppDialog}
                  />
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <SupportDialog
          open={suppDialog}
          handleClose={() => {
            setSuppDialog(false);
          }}
          setDialog={setDialog}
          loading={loading}
          setLoading={setLoading}
        />
      </Box>
    </header>
  );
}

export default React.memo(withRouter(Navbar), (prevProps, nextProps) => {
  const { ...prevCheck } = prevProps;
  const { ...nextCheck } = nextProps;

  return isEqual(prevCheck, nextCheck);
});
