export const mainErrorMessage =
  "Něco se nepovedlo. Zkontrolujte připojení k internetu a zopakujte akci. V případě přetrvání potíží nás můžete kdykoliv kontaktovat a my vám rádi pomůžeme.";

export const mainPermMessage = "Tato funkce je uzamčena";

export const userNotFoundErrorMessage =
  "Neověřená relace. Před pokračováním se prosím znovu přihlašte ke svému účtu.";

export const needLogoutErrorMessage =
  "Pro správné dokončení změny plátcovství je zapotřebí se manuálně odhlásit a znovu přihlásit.";

export const invitationInvalidErrorMessage =
  "Omlouváme se, vámi obdržená pozvánka je již neplatná.";

// Login
export const errorWrongPassword =
  "Přihlášení se nezdařilo, překontrolujte přihlašovací údaje a přihlášení opakujte.";

// Export
export const exportError =
  "Omlouváme se, export se nezdařil. Zopakujte akci. Je možné že váš prohlížeč nepodporuje tuto funkci. V případě přetrvání potíží nás můžete kdykoliv kontaktovat a my vám rádi pomůžeme.";

export const permDialog = {
  closable: true,
  submitText: "Pokračovat",
  text: "Tato funkce je uzamčena",
  tooltip: (
    <>
      Pokud potřebujete tuto funkci využít, požádejte majitele účtu o její
      odemčení. Více informací naleznete{" "}
      <a
        href="https://profaktura.cz/podpora?sekce=nastaveni_profaktury&clanek=Nastaveni_uzivatelskych_opravneni"
        target="_blank"
        style={{ fontWeight: "bold", color: "white" }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.open(
            "https://profaktura.cz/podpora?sekce=nastaveni_profaktury&clanek=Nastaveni_uzivatelskych_opravneni",
            "_blank"
          );
        }}
      >
        zde.
      </a>
    </>
  ),
};
