import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://97863e03e933d456e47bdbfae3b25c69@o4507689488744448.ingest.de.sentry.io/4507689496019024",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  ////////////////////////////////////
  ////// Performance Monitoring //////
  ////////////////////////////////////

  // Capture 100% of the transactions
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "profaktura.cz",
    "app.profaktura.cz",
    /^\//,
  ],

  ////////////////////////////
  ////// Session Replay //////
  ////////////////////////////

  replaysSessionSampleRate: 0.1,

  replaysOnErrorSampleRate: 1.0,

  attachStacktrace: true,
  autoSessionTracking: true,
});

export default Sentry;
