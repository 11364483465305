import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { areObjectsSame } from "../../../Core/Pages/Main/Faktura/FakturaTemplates/helpFunctions";

const ShopContext = createContext();
const initState = { page: 0, maxPage: 0, paymentId: "PAYMENT_CARD" };
export const ShopContextProvider = ({ children }) => {
  const location = useLocation();
  const [shopState, setShopState] = useState(initState);

  useEffect(() => {
    if (
      location.pathname !== "/shop" &&
      !areObjectsSame(shopState, initState)
    ) {
      console.log("Changing state to init");
      setShopState(initState);
    }
  }, [location.pathname]);

  return (
    <ShopContext.Provider value={{ shopState, setShopState }}>
      {children}
    </ShopContext.Provider>
  );
  /*if (location.pathname === "/shop") {
    return (
      <ShopContext.Provider value={{ shopState, setShopState }}>
        {children}
      </ShopContext.Provider>
    );
  } else {
    return <>{children}</>;
  }*/
};

export const useShopContext = () => useContext(ShopContext);
