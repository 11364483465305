export const ADR_ID = "adrAd";
export const CEN_FREE_ID = "cenAd";
export const CEN_PLUS_ID = "cenPlusAd";
export const CEN_PRO_ID = "cenProsAd";

export function setShowAd(adId) {
  if (!localStorage) return;
  localStorage.setItem(adId, true);
}

export function getShowAd(adId) {
  return localStorage?.getItem(adId) || false;
}

export function removeShowAd(key) {
  if (!localStorage) return;
  localStorage.removeItem(key);
}

export const locStorAvaiable = () =>
  typeof window !== "undefined" &&
  "localStorage" in window &&
  window.localStorage !== null;
