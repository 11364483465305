export const currenciesList = [
  {
    name: "Koruna česká",
    iso: "CZK",
    symbol: "Kč",
    after: true,
    text: "Nejčastější",
  },
  { name: "Euro", iso: "EUR", symbol: "€", after: false },
  { name: "Dollar", iso: "USD", symbol: "$", after: false },
  { name: "Libra", iso: "GBP", symbol: "£", after: false },
  {
    name: "Australský dolar",
    iso: "AUD",
    symbol: "A$",
    after: false,
    text: "Načítající kurz",
  },
  { name: "Brazilský real", iso: "BRL", symbol: "R$", after: false },
  { name: "Bulharský lev", iso: "BGN", symbol: "лв", after: true },
  { name: "Čínský juan", iso: "CNY", symbol: "¥", after: true },
  { name: "Dánská koruna", iso: "DKK", symbol: "kr (DKK)", after: true },
  { name: "Filipínské peso", iso: "PHP", symbol: "₱", after: true },
  { name: "Hongkongský dolar", iso: "HKD", symbol: "HK$", after: false },
  { name: "Indická rupie", iso: "INR", symbol: "₹", after: false },
  { name: "Indonéská rupie", iso: "IDR", symbol: "Rp", after: false },
  { name: "Islandská koruna", iso: "ISK", symbol: "kr (ISK)", after: true },
  { name: "Izraelský šekel", iso: "ILS", symbol: "₪", after: true },
  { name: "Japonský jen", iso: "JPY", symbol: "¥", after: true },
  { name: "Jihoafrický rand", iso: "ZAR", symbol: "R", after: false },
  { name: "Jihokorejský won", iso: "KRW", symbol: "₩", after: true },
  { name: "Kanadský dolar", iso: "CAD", symbol: "CA$", after: false },
  { name: "Maďarský forint", iso: "HUF", symbol: "Ft", after: true },
  { name: "Malajsijský ringgit", iso: "MYR", symbol: "RM", after: false },
  { name: "Mexické peso", iso: "MXN", symbol: "MXN$", after: false },
  { name: "Norská koruna", iso: "NOK", symbol: "kr (NOK)", after: true },
  { name: "Novozélandský dolar", iso: "NZD", symbol: "NZ$", after: false },
  { name: "Polský zlotý", iso: "PLN", symbol: "zł", after: true },
  { name: "Rumunský lei", iso: "RON", symbol: "lei", after: true },
  { name: "Singapurský dolar", iso: "SGD", symbol: "SG$", after: false },
  { name: "Švédská koruna", iso: "SEK", symbol: "kr (SEK)", after: true },
  { name: "Švýcarský frank", iso: "CHF", symbol: "CHf", after: true },
  { name: "Thajský baht", iso: "THB", symbol: "฿", after: false },
  { name: "Turecká lira", iso: "TRY", symbol: "₺", after: true },
  // nezname kurzy
  {
    name: "Rubl",
    iso: "RUB",
    symbol: "₽",
    after: true,
    text: "Bez načtení kurzu",
  },
  { name: "Argentinské peso", iso: "ARS", symbol: "Arg$", after: false },
  { name: "Kolumbijské peso", iso: "COP", symbol: "COL$", after: false },
  { name: "Bitcoin", iso: "BTC", symbol: "BTC", after: true },
  { name: "Ethereum", iso: "ETH", symbol: "ETH", after: true },
];

export const fullCurrenciesList = [
  { short: "AFN", name: "Afghan Afghani", symbol: "؋" },
  { short: "ALL", name: "Albanian Lek", symbol: "Lek" },
  { short: "DZD", name: "Algerian Dinar", symbol: "دج" },
  { short: "AOA", name: "Angolan Kwanza", symbol: "Kz" },
  { short: "ARS", name: "Argentine Peso", symbol: "$" },
  { short: "AMD", name: "Armenian Dram", symbol: "֏" },
  { short: "AWG", name: "Aruban Florin", symbol: "ƒ" },
  { short: "AUD", name: "Australian Dollar", symbol: "$" },
  { short: "AZN", name: "Azerbaijani Manat", symbol: "m" },
  { short: "BSD", name: "Bahamian Dollar", symbol: "B$" },
  { short: "BHD", name: "Bahraini Dinar", symbol: ".د.ب" },
  { short: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
  { short: "BBD", name: "Barbadian Dollar", symbol: "Bds$" },
  { short: "BYR", name: "Belarusian Ruble", symbol: "Br" },
  { short: "BEF", name: "Belgian Franc", symbol: "fr" },
  { short: "BZD", name: "Belize Dollar", symbol: "$" },
  { short: "BMD", name: "Bermudan Dollar", symbol: "$" },
  { short: "BTN", name: "Bhutanese Ngultrum", symbol: "Nu." },
  { short: "BTC", name: "Bitcoin", symbol: "฿" },
  { short: "BOB", name: "Bolivian Boliviano", symbol: "Bs." },
  { short: "BAM", name: "Bosnia-Herzegovina Convertible Mark", symbol: "KM" },
  { short: "BWP", name: "Botswanan Pula", symbol: "P" },
  { short: "BRL", name: "Brazilian Real", symbol: "R$" },
  { short: "GBP", name: "British Pound Sterling", symbol: "£" },
  { short: "BND", name: "Brunei Dollar", symbol: "B$" },
  { short: "BGN", name: "Bulgarian Lev", symbol: "Лв." },
  { short: "BIF", name: "Burundian Franc", symbol: "FBu" },
  { short: "KHR", name: "Cambodian Riel", symbol: "KHR" },
  { short: "CAD", name: "Canadian Dollar", symbol: "$" },
  { short: "CVE", name: "Cape Verdean Escudo", symbol: "$" },
  { short: "KYD", name: "Cayman Islands Dollar", symbol: "$" },
  { short: "XOF", name: "CFA Franc BCEAO", symbol: "CFA" },
  { short: "XAF", name: "CFA Franc BEAC", symbol: "FCFA" },
  { short: "XPF", name: "CFP Franc", symbol: "₣" },
  { short: "CLP", name: "Chilean Peso", symbol: "$" },
  { short: "CNY", name: "Chinese Yuan", symbol: "¥" },
  { short: "COP", name: "Colombian Peso", symbol: "$" },
  { short: "KMF", name: "Comorian Franc", symbol: "CF" },
  { short: "CDF", name: "Congolese Franc", symbol: "FC" },
  { short: "CRC", name: "Costa Rican ColÃ³n", symbol: "₡" },
  { short: "HRK", name: "Croatian Kuna", symbol: "kn" },
  { short: "CUC", name: "Cuban Convertible Peso", symbol: "$, CUC" },
  { short: "CZK", name: "Czech Republic Koruna", symbol: "Kč" },
  { short: "DKK", name: "Danish Krone", symbol: "Kr." },
  { short: "DJF", name: "Djiboutian Franc", symbol: "Fdj" },
  { short: "DOP", name: "Dominican Peso", symbol: "$" },
  { short: "XCD", name: "East Caribbean Dollar", symbol: "$" },
  { short: "EGP", name: "Egyptian Pound", symbol: "ج.م" },
  { short: "ERN", name: "Eritrean Nakfa", symbol: "Nfk" },
  { short: "EEK", name: "Estonian Kroon", symbol: "kr" },
  { short: "ETB", name: "Ethiopian Birr", symbol: "Nkf" },
  { short: "EUR", name: "Euro", symbol: "€" },
  { short: "FKP", name: "Falkland Islands Pound", symbol: "£" },
  { short: "FJD", name: "Fijian Dollar", symbol: "FJ$" },
  { short: "GMD", name: "Gambian Dalasi", symbol: "D" },
  { short: "GEL", name: "Georgian Lari", symbol: "ლ" },
  { short: "DEM", name: "German Mark", symbol: "DM" },
  { short: "GHS", name: "Ghanaian Cedi", symbol: "GH₵" },
  { short: "GIP", name: "Gibraltar Pound", symbol: "£" },
  { short: "GRD", name: "Greek Drachma", symbol: "₯, Δρχ, Δρ" },
  { short: "GTQ", name: "Guatemalan Quetzal", symbol: "Q" },
  { short: "GNF", name: "Guinean Franc", symbol: "FG" },
  { short: "GYD", name: "Guyanaese Dollar", symbol: "$" },
  { short: "HTG", name: "Haitian Gourde", symbol: "G" },
  { short: "HNL", name: "Honduran Lempira", symbol: "L" },
  { short: "HKD", name: "Hong Kong Dollar", symbol: "$" },
  { short: "HUF", name: "Hungarian Forint", symbol: "Ft" },
  { short: "ISK", name: "Icelandic KrÃ³na", symbol: "kr" },
  { short: "INR", name: "Indian Rupee", symbol: "₹" },
  { short: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
  { short: "IRR", name: "Iranian Rial", symbol: "﷼" },
  { short: "IQD", name: "Iraqi Dinar", symbol: "د.ع" },
  { short: "ILS", name: "Israeli New Sheqel", symbol: "₪" },
  { short: "ITL", name: "Italian Lira", symbol: "L,£" },
  { short: "JMD", name: "Jamaican Dollar", symbol: "J$" },
  { short: "JPY", name: "Japanese Yen", symbol: "¥" },
  { short: "JOD", name: "Jordanian Dinar", symbol: "ا.د" },
  { short: "KZT", name: "Kazakhstani Tenge", symbol: "лв" },
  { short: "KES", name: "Kenyan Shilling", symbol: "KSh" },
  { short: "KWD", name: "Kuwaiti Dinar", symbol: "ك.د" },
  { short: "KGS", name: "Kyrgystani Som", symbol: "лв" },
  { short: "LAK", name: "Laotian Kip", symbol: "₭" },
  { short: "LVL", name: "Latvian Lats", symbol: "Ls" },
  { short: "LBP", name: "Lebanese Pound", symbol: "£" },
  { short: "LSL", name: "Lesotho Loti", symbol: "L" },
  { short: "LRD", name: "Liberian Dollar", symbol: "$" },
  { short: "LYD", name: "Libyan Dinar", symbol: "د.ل" },
  { short: "LTL", name: "Lithuanian Litas", symbol: "Lt" },
  { short: "MOP", name: "Macanese Pataca", symbol: "$" },
  { short: "MKD", name: "Macedonian Denar", symbol: "ден" },
  { short: "MGA", name: "Malagasy Ariary", symbol: "Ar" },
  { short: "MWK", name: "Malawian Kwacha", symbol: "MK" },
  { short: "MYR", name: "Malaysian Ringgit", symbol: "RM" },
  { short: "MVR", name: "Maldivian Rufiyaa", symbol: "Rf" },
  { short: "MRO", name: "Mauritanian Ouguiya", symbol: "MRU" },
  { short: "MUR", name: "Mauritian Rupee", symbol: "₨" },
  { short: "MXN", name: "Mexican Peso", symbol: "$" },
  { short: "MDL", name: "Moldovan Leu", symbol: "L" },
  { short: "MNT", name: "Mongolian Tugrik", symbol: "₮" },
  { short: "MAD", name: "Moroccan Dirham", symbol: "MAD" },
  { short: "MZM", name: "Mozambican Metical", symbol: "MT" },
  { short: "MMK", name: "Myanmar Kyat", symbol: "K" },
  { short: "NAD", name: "Namibian Dollar", symbol: "$" },
  { short: "NPR", name: "Nepalese Rupee", symbol: "₨" },
  { short: "ANG", name: "Netherlands Antillean Guilder", symbol: "ƒ" },
  { short: "TWD", name: "New Taiwan Dollar", symbol: "$" },
  { short: "NZD", name: "New Zealand Dollar", symbol: "$" },
  { short: "NIO", name: "Nicaraguan CÃ³rdoba", symbol: "C$" },
  { short: "NGN", name: "Nigerian Naira", symbol: "₦" },
  { short: "KPW", name: "North Korean Won", symbol: "₩" },
  { short: "NOK", name: "Norwegian Krone", symbol: "kr" },
  { short: "OMR", name: "Omani Rial", symbol: ".ع.ر" },
  { short: "PKR", name: "Pakistani Rupee", symbol: "₨" },
  { short: "PAB", name: "Panamanian Balboa", symbol: "B/." },
  { short: "PGK", name: "Papua New Guinean Kina", symbol: "K" },
  { short: "PYG", name: "Paraguayan Guarani", symbol: "₲" },
  { short: "PEN", name: "Peruvian Nuevo Sol", symbol: "S/." },
  { short: "PHP", name: "Philippine Peso", symbol: "₱" },
  { short: "PLN", name: "Polish Zloty", symbol: "zł" },
  { short: "QAR", name: "Qatari Rial", symbol: "ق.ر" },
  { short: "RON", name: "Romanian Leu", symbol: "lei" },
  { short: "RUB", name: "Russian Ruble", symbol: "₽" },
  { short: "RWF", name: "Rwandan Franc", symbol: "FRw" },
  { short: "SVC", name: "Salvadoran ColÃ³n", symbol: "₡" },
  { short: "WST", name: "Samoan Tala", symbol: "SAT" },
  { short: "SAR", name: "Saudi Riyal", symbol: "﷼" },
  { short: "RSD", name: "Serbian Dinar", symbol: "din" },
  { short: "SCR", name: "Seychellois Rupee", symbol: "SRe" },
  { short: "SLL", name: "Sierra Leonean Leone", symbol: "Le" },
  { short: "SGD", name: "Singapore Dollar", symbol: "$" },
  { short: "SKK", name: "Slovak Koruna", symbol: "Sk" },
  { short: "SBD", name: "Solomon Islands Dollar", symbol: "Si$" },
  { short: "SOS", name: "Somali Shilling", symbol: "Sh.so." },
  { short: "ZAR", name: "South African Rand", symbol: "R" },
  { short: "KRW", name: "South Korean Won", symbol: "₩" },
  { short: "XDR", name: "Special Drawing Rights", symbol: "SDR" },
  { short: "LKR", name: "Sri Lankan Rupee", symbol: "Rs" },
  { short: "SHP", name: "St. Helena Pound", symbol: "£" },
  { short: "SDG", name: "Sudanese Pound", symbol: ".س.ج" },
  { short: "SRD", name: "Surinamese Dollar", symbol: "$" },
  { short: "SZL", name: "Swazi Lilangeni", symbol: "E" },
  { short: "SEK", name: "Swedish Krona", symbol: "kr" },
  { short: "CHF", name: "Swiss Franc", symbol: "CHf" },
  { short: "SYP", name: "Syrian Pound", symbol: "LS" },
  { short: "STD", name: "São Tomé and Príncipe Dobra", symbol: "Db" },
  { short: "TJS", name: "Tajikistani Somoni", symbol: "SM" },
  { short: "TZS", name: "Tanzanian Shilling", symbol: "TSh" },
  { short: "THB", name: "Thai Baht", symbol: "฿" },
  { short: "TOP", name: `Tongan pa'anga`, symbol: "$" },
  { short: "TTD", name: "Trinidad & Tobago Dollar", symbol: "$" },
  { short: "TND", name: "Tunisian Dinar", symbol: "ت.د" },
  { short: "TRY", name: "Turkish Lira", symbol: "₺" },
  { short: "TMT", name: "Turkmenistani Manat", symbol: "T" },
  { short: "UGX", name: "Ugandan Shilling", symbol: "USh" },
  { short: "UAH", name: "Ukrainian Hryvnia", symbol: "₴" },
  { short: "AED", name: "United Arab Emirates Dirham", symbol: "إ.د" },
  { short: "UYU", name: "Uruguayan Peso", symbol: "$" },
  { short: "USD", name: "US Dollar", symbol: "$" },
  { short: "UZS", name: "Uzbekistan Som", symbol: "лв" },
  { short: "VUV", name: "Vanuatu Vatu", symbol: "VT" },
  { short: "VEF", name: "Venezuelan BolÃvar", symbol: "Bs" },
  { short: "VND", name: "Vietnamese Dong", symbol: "₫" },
  { short: "YER", name: "Yemeni Rial", symbol: "﷼" },
  { short: "ZMK", name: "Zambian Kwacha", symbol: "ZK" },
];
