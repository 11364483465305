export default function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isSameDay(date1, date2) {
  if (!isValidDate(date1) || !isValidDate(date2)) return false;
  try {
    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log("ERROR Date isSameDay ", err);
    return false;
  }
}
