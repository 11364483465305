import React, { useState } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import { ReactComponent as Setup } from "../../Design/Graphics/svg/Navigation/Setup.svg";
import { ReactComponent as Support } from "../../Design/Graphics/svg/Navigation/Support.svg";
// PHONE
import { ReactComponent as Add } from "../../Design/Graphics/svg/Navigation/Phone/Add.svg";
import { ReactComponent as Bookmark } from "../../Design/Graphics/svg/Navigation/Phone/Bookmark.svg";
import { ReactComponent as Contact } from "../../Design/Graphics/svg/Navigation/Phone/Contact.svg";
import { ReactComponent as Dollar } from "../../Design/Graphics/svg/Navigation/Phone/Dollar.svg";
import { ReactComponent as Graph } from "../../Design/Graphics/svg/Navigation/Phone/Graph.svg";

function ResizeIcon({ icon }) {
  let editedIcon = React.cloneElement(icon, {
    style: {
      fill: "black",
      width: "30px",
      height: "30px",
    },
  });

  return editedIcon;
}

// MAIN NAVBAR

export const mainNavigation = [
  {
    name: "Nová faktura",
    path: "/faktura/nova",
    icon: <AddCircleOutlineRoundedIcon />,
  },
  {
    name: "Přehled",
    path: "/faktura/list",
    icon: <LibraryBooksRoundedIcon />,
  },
  {
    name: "Adresář",
    path: "/adresar/list",
    icon: <FolderSharedRoundedIcon />,
  },
  {
    name: "Ceník",
    path: "/cenik/list",
    icon: <PaymentsRoundedIcon />,
  },
  {
    name: "Statistika",
    path: "/statistika",
    icon: <QueryStatsRoundedIcon />,
  },
];

// PROFILE BUTTON

export const navigationMenu = [
  {
    name: "Nastavení",
    icon: <Setup />,
    text: "Správa předplatného, nastavní výchozích hodnot, vzhled faktury, nastavení emailu...",
    phoneText: "Správa předplatného, nastavní výchozích hodnot, ...",
    path: "/settings",
  },
  {
    name: "Podpora",
    icon: <Support />,
    text: "Kontaktní formulář, návody, rady a tipy pro ulehčení práce...",
    phoneText: "Kontakt, návody, rady a tipy pro ulehčení práce",
    path: "https://profaktura.cz/podpora",
    fullPath: true,
    podpora: true,
  },
];

// PROFILE MOBILE

export const navigationMobile = [
  {
    name: "Nová faktura",
    icon: <Add />,
    text: "Formulář pro tvorbu nové faktury a zálohovky",
    path: "/faktura/nova",
  },
  {
    name: "Přehled faktur",
    icon: <Bookmark />,
    text: "Přehled, třídění faktur a vyhledávání faktur",
    path: "/faktura/list",
  },
  {
    name: "Ceník",
    icon: <Dollar />,
    text: "Ceník, tvorba položek ceníku a export ceníku",
    path: "/cenik/list",
  },
  {
    name: "Adresář",
    icon: <Contact />,
    text: "Adresář kontaktů, tvorba kontaktů a export kontaktů",
    path: "/adresar/list",
  },
  {
    name: "Statistika",
    icon: <Graph />,
    text: "Grafy, přehledy statistiky a cashflow",
    path: "/statistika",
  },
];

// SECONDARY NAVBARS

export const smallNavigation = [
  {
    path: "/faktura",
    routes: [
      //{ name: "Nová faktura", path: "/faktura/nova" },
      { name: "Všechny faktury", path: "/faktura/list" },
      { name: "Šablony faktur", path: "/sablona/list" },
      { name: "Export", path: "/faktura/export" },
    ],
  },
  {
    path: "/cenik",
    routes: [
      { name: "Nová položka", path: "/cenik/novy" },
      { name: "Všechny položky", path: "/cenik/list" },
      { name: "Export", path: "/cenik/export" },
    ],
  },
  {
    path: "/adresar",
    routes: [
      { name: "Nový kontakt", path: "/adresar/novy" },
      { name: "Všechny kontakty", path: "/adresar/list" },
      { name: "Export", path: "/adresar/export" },
    ],
  },
  {
    path: "/",
    routes: [
      //{ name: "Nová faktura", path: "/faktura/nova" },
      { name: "Všechny faktury", path: "/faktura/list" },
      { name: "Šablony faktur", path: "/sablona/list" },
      { name: "Export", path: "/faktura/export" },
    ],
  },
  {
    path: "/settings/banka",
    routes: [
      { name: "Bankovní účty", path: "/settings/banka/list" },
      { name: "Nový účet", path: "/settings/banka/pridat" },
    ],
  },
  {
    path: "/settings/fakturace",
    routes: [
      { name: "Výchozí hodnoty", path: "/settings/fakturace" },
      { name: "Číslování faktur", path: "/settings/cislovani" },
      { name: "Číslování zálohovek", path: "/settings/zalohovky" },
    ],
  },
  {
    path: "/subscription",
    routes: [
      { name: "Správa", path: "/subscription" },
      { name: "Nový uživatel", path: "/subscription/subuser" },
    ],
  },
];
