import React, { lazy } from "react";

const ActionController = lazy(() =>
  import("../../Firebase/ActionHandlers/ActionController")
);
const SendEmail = lazy(() => import("../../Firebase/ActionHandlers/SendEmail"));
const InvitationHandle = lazy(() =>
  import(
    "../../Core/Pages/Settings/SubscriptionPage/SubUsers/InvitationHandle/InvitationHandle"
  )
);

const ResetPassword = lazy(() =>
  import("../../Firebase/ActionHandlers/ResetPassword/ResetPassword")
);

const actionRoutes = [
  {
    path: "/verifikace",
    element: <ActionController />,
    noNavbar: true,
    public: true,
  },
  {
    path: "/passReset",
    element: <ResetPassword />,
    noNavbar: true,
    public: true,
  },
  {
    path: "/sendEmail",
    element: <SendEmail />,
    noNavbar: true,
  },
  {
    path: "/acceptance",
    element: <InvitationHandle />,
    noNavbar: true,
    public: true,
  },
];

export default actionRoutes;
