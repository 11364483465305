function isValidObject(yourVariable) {
  if (
    typeof yourVariable === "object" &&
    !Array.isArray(yourVariable) &&
    yourVariable !== null
  ) {
    return true;
  }
  return false;
}

function roundToNumber(num, roundTo = 2) {
  if (num === 0) return 0;
  return +(
    Math.round(parseFloat(num) + Number.EPSILON + `e+${roundTo}`) +
    `e-${roundTo}`
  );
}

// NEPLATCE - NO DPH
export function getFakturaTotal(rows) {
  let total = 0;
  for (let i = 0; i < rows.length; i++) {
    if (
      isNaN(rows[i].cost) ||
      (!rows[i].cost && rows[i].cost !== 0) ||
      (isNaN(rows[i].count) && rows[i].count !== "")
    ) {
      continue;
    }
    try {
      total +=
        parseFloat(rows[i].cost) *
        (rows[i].count === "" ? 1 : parseFloat(rows[i].count));
    } catch (err) {
      console.log("ERR COUNTING TOTAL ", err);
    }
  }
  return total;
}

// PLATCE

/*

dphCalc - 0/1
0 - ze zakladu
1 - z konecne castky

dphCarr - 0/1
0 - Ne - v zakladu, vse ok
1 - Ano (DPH SE NEPOCITA) - stejne jako u neplatce

*/

// dphCalc = 0
function calcFromBase(cost, dph, count, roundTo = 2) {
  let dphCost = roundToNumber(cost * (dph / 100), roundTo);
  return { base: cost * count, tax: dphCost * count };
}
// dphCalc = 1
function calcFromEnd(cost, dph, count, roundTo = 2) {
  let totalSmaller = roundToNumber(cost / (1 + dph / 100), roundTo);
  return { base: totalSmaller * count, tax: (cost - totalSmaller) * count };
}

// GET MOST OCCURENT ITEM IN ARRAY
function getDph(arr) {
  return [...arr]
    .sort(
      (a, b) =>
        arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
    )
    .pop();
}

// ROUND TO FULL NUMBER
export function isRoundable(total) {
  return total - Math.round(total) !== 0;
}

export function getZaokrouhleni(
  total,
  platce,
  dphCalc,
  dphCarr,
  fakturaPlatceData = []
) {
  let dphs = [];

  let diff = -roundToNumber(total - Math.round(total), 3); // ZAOKROUHLENI PRED DPH

  if (!platce || dphCarr === 1 || fakturaPlatceData.length === 0) {
    return diff;
  }

  let dph;
  dphs = fakturaPlatceData.map((row) => row.dph);
  if (dphs.includes(21) || dphs.includes("21")) {
    dph = 21;
  } else {
    dph = getDph(dphs);
  }

  let calc;
  if (dphCalc === 1 && dphCarr === 0) {
    calc = calcFromBase(diff, dph, 1, 3);
  } else {
    calc = calcFromEnd(diff, dph, 1, 3);
  }

  return { dph, base: calc.base };
}

export function getFakturaPlatceTotal(fakturaPlatceData) {
  if (!fakturaPlatceData) return 0;
  let total = 0;
  fakturaPlatceData.map((row) => {
    total += row.base + row.tax;
  });
  return total;
}

export function getFakturaPlatceData(rows, dphCarr = 0, dphCalc = 0) {
  let total = []; // {dph: 21, base: 1000, tax: 210}
  for (let i = 0; i < rows.length; i++) {
    if (!isValidObject(rows[i])) continue;
    if (!("cost" in rows[i])) continue;
    let costString;
    try {
      costString = rows[i].cost.toString().replace(",", ".");
    } catch (err) {
      console.log("ERR COST ", err);
      continue;
    }

    let countString;
    let count;
    try {
      countString = rows[i].count.toString().replace(",", ".");
      count = countString === "" ? 1 : parseFloat(countString);
    } catch (err) {
      console.log("ERR COUNT ", err);
      continue;
    }

    if (
      (!costString && costString !== 0) ||
      isNaN(costString) ||
      rows[i].dph === ""
    ) {
      continue;
    }
    try {
      let dph = parseInt(rows[i].dph);
      let cost = parseFloat(costString);

      let calc;
      if (dphCalc == 1 && dphCarr == 0) {
        calc = calcFromEnd(cost, dph, count);
      } else {
        calc = calcFromBase(cost, dph, count);
      }

      const index = total.findIndex((e) => e.dph === dph);
      // DPH ALREADY IN
      if (index > -1) {
        total[index].base += calc.base;
        total[index].tax += calc.tax;
      }
      // DPH NOT FOUND - ADD NEW DPH
      else {
        total.push({ dph, base: calc.base, tax: calc.tax });
      }
    } catch (err) {
      console.log("ERR COUNTING TOTAL ", err);
    }
  }

  return total;
}

// Be careful if changing to also change QRWorker
export function getFakturaNeplatceDataLines(rows, dphCarr = 0) {
  let total = [];
  for (let i = 0; i < rows.length; i++) {
    if (
      isNaN(rows[i].cost) ||
      (!rows[i].cost && rows[i].cost !== 0) ||
      (isNaN(rows[i].count) && rows[i].count !== "")
    ) {
      continue;
    }
    try {
      let unitPrice = parseFloat(rows[i].cost);
      let count = rows[i].count === "" ? 1 : parseFloat(rows[i].count);
      let price = unitPrice * count;

      let dph = dphCarr == 1 ? (rows[i]?.dph ? parseInt(rows[i].dph) : 0) : 0;

      total.push({
        mj: rows[i].mj,
        name: rows[i].name,
        count,
        base: price,
        baseUnit: unitPrice,
        dph,
      });
    } catch (err) {
      console.log("ERR COUNTING TOTAL ", err);
    }
  }
  return total;
}

export function getFakturaPlatceDataLines(rows, dphCarr = 0, dphCalc = 0) {
  let total = []; // {dph: 21, base: 1000, tax: 210}
  for (let i = 0; i < rows.length; i++) {
    if (!isValidObject(rows[i])) continue;
    if (!("cost" in rows[i])) continue;
    let costString;
    try {
      costString = rows[i].cost.toString().replace(",", ".");
    } catch (err) {
      console.log("ERR COST ", err);
      continue;
    }

    let countString;
    let count;
    try {
      countString = rows[i].count.toString().replace(",", ".");
      count = countString === "" ? 1 : parseFloat(countString);
    } catch (err) {
      console.log("ERR COUNT ", err);
      continue;
    }

    if (
      (!costString && costString !== 0) ||
      isNaN(costString) ||
      rows[i].dph === ""
    ) {
      continue;
    }
    try {
      let dph = parseInt(rows[i].dph);
      let cost = parseFloat(costString);

      let calc;
      let calcUnit;
      if (dphCalc == 1 && dphCarr == 0) {
        calc = calcFromEnd(cost, dph, count);
        calcUnit = calcFromEnd(cost, dph, 1);
      } else {
        calc = calcFromBase(cost, dph, count);
        calcUnit = calcFromBase(cost, dph, 1);
      }

      total.push({
        mj: rows[i].mj,
        name: rows[i].name,
        count,
        dph,
        base: calc.base,
        tax: calc.tax,
        baseUnit: calcUnit.base,
        taxUnit: calcUnit.tax,
      });
    } catch (err) {
      console.log("ERR ADDING ITEM ", err);
    }
  }

  return total;
}
