// predefined[sale_id] - sales

////////////////// User-specific
const user_specific = {
  l: {
    smallTitle: "- Časově omezená nabídka -",
    title: "20% SLEVA",
    text: "Využijte mimořádné nabídky",
  },
};
////////////////// Global
const global = {
  // Black friday
  bf: {
    smallTitle: "- BLACK FRIDAY -",
    title: "30% SLEVA",
    text: "Využijte mimořádné nabídky",
    global: true,
    p: 5,
  },
  // Chrismas
  ch: {
    smallTitle: "- Vánoční nabídka -",
    title: "30% SLEVA",
    text: "Využijte mimořádné nabídky",
    global: true,
    p: 5,
  },
  // New year
  ny: {
    smallTitle: "- Novoroční sleva -",
    title: "30% SLEVA",
    text: "Využijte mimořádné nabídky",
    global: true,
    p: 5,
  },
  // Spring
  sp: {
    smallTitle: "- Časově omezená nabídka -",
    title: "25% SLEVA",
    text: "Využijte mimořádné nabídky",
    global: true,
    p: 5,
  },
  // Summer
  sm: {
    smallTitle: "- Časově omezená nabídka -",
    title: "25% SLEVA",
    text: "Využijte mimořádné nabídky",
    global: true,
    p: 5,
  },
};

const allSaleData = { ...user_specific, ...global };
export default allSaleData;
