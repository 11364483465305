import "./App.css";
import { createTheme, CssBaseline } from "@mui/material";
import coreThemeFile from "../Resources/Design/SourcesJS/themes";
import React, { Suspense, useEffect, useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { coreRoutes } from "../Resources/Routes/routes";
import ScrollUpComp from "../Resources/Core/Components/Base/ScrollUpComp";
import { withLocationInfo } from "./withLocationInfo";
import { withAuth } from "./withAuth";
import { ShopContextProvider } from "../Resources/Sales/PurchasePages/ShoppingBasket/ShopContextProvider";
import { SalesContextProvider } from "../Resources/Sales/SalesContext";
import ErrorHandle from "./ErrorHandle/ErrorHandle";
import PrivateRoute from "./AppComponents/PrivateRoute";
import { withSalesContext } from "../Resources/Sales/withSalesContext";
import PageContainerWrap from "./AppComponents/PageContainerWrap";
import LoadingBackdrop from "../Resources/Core/Components/Layouts/LoadingBackdrop";
import { setAnalyticsConsent } from "../Resources/Firebase/init";

// Default mui theme | designs
export const theme = createTheme(coreThemeFile());

// Default company option - changes prices etc. | only frontend visual
export const PLATCI_DPH = false;

// Propagates sales, prices
const ContextProviders = ({ children, ...props }) => (
  <SalesContextProvider {...props}>
    <ShopContextProvider>{children}</ShopContextProvider>
  </SalesContextProvider>
);

function App(props) {
  const { userState, claims, subuserCompanies, authLoading, getPermissions } =
    props;

  // User authentication
  const authData = useMemo(
    () => ({
      userState,
      claims,
      subuserCompanies,
      authLoading,
      getPermissions,
    }),
    [userState, claims, subuserCompanies, authLoading, getPermissions]
  );

  useEffect(() => {
    setAnalyticsConsent();
  }, []);

  // Controls default page components - navbar, footer, ad banners...
  const PageContainer = ({ route }) => {
    const ElementWithSale = useMemo(
      () =>
        withSalesContext((salesProps) => (
          <PageContainerWrap
            route={route}
            userState={userState}
            claims={claims}
            {...salesProps}
          />
        )),
      [route]
    );
    return <ElementWithSale />;
  };

  return (
    <>
      <CssBaseline />
      <HelmetProvider>
        <div
          className="App"
          style={{ backgroundColor: "#FBFBFB", height: "100%" }}
        >
          <Suspense fallback={<LoadingBackdrop open={true} />}>
            <ScrollUpComp />
            <ContextProviders {...props}>
              <Routes>
                {coreRoutes.map((route, index) => (
                  <Route
                    exact
                    key={"ContainerRoute" + index}
                    element={
                      /* Catches unhandled component errors */
                      <ErrorHandle route={route}>
                        {/* Controls redirecting based on account state */}
                        <PrivateRoute
                          key={"PrivateRoute" + index}
                          route={route}
                          authData={authData}
                          loadVisual={true}
                        />
                      </ErrorHandle>
                    }
                  >
                    <Route
                      exact
                      path={route.path}
                      key={"RouteCore" + index}
                      element={<PageContainer route={route} />}
                    />
                  </Route>
                ))}
              </Routes>
            </ContextProviders>
          </Suspense>
        </div>
      </HelmetProvider>
    </>
  );
}

export default withAuth(withLocationInfo(App));
