import { useState } from "react";
import GraphicDialog from "./GraphicDialog";
import { useNavigate } from "react-router-dom";

export const withAdDialog = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const [adDialog, setAdDialog] = useState(null);

    return (
      <>
        <GraphicDialog
          open={Boolean(adDialog)}
          handleClose={() => setAdDialog(null)}
          data={adDialog || null}
          navigate={navigate}
        />
        <Component setAdDialog={setAdDialog} {...props} />
      </>
    );
  };

  return Wrapper;
};
