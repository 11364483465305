import * as React from "react";
import { Tooltip } from "@mui/material";
import { IconButton as IcButton } from "@mui/material";
import { Motion } from "../Motion/Motion";
import { navbarBlue, textGrey } from "../../../Design/SourcesJS/themes";

export default function IconButton(props) {
  const {
    sxProps,
    icon,
    dontHover = false,
    iconProps,
    tooltip = "",
    placement = "top",
    scale = 1.08,
    enterDelay,
    disabled = false,
    iconOther = {},
    ...otherProps
  } = props;

  const Icon = () => {
    const editedIcon = React.cloneElement(icon, {
      style: {
        ...{
          width: "30px",
          height: "30px",
          fill: disabled ? textGrey : navbarBlue,
        },
        ...(iconProps || {}),
      },
      ...iconOther,
    });
    return editedIcon;
  };

  /*const otherProps = { ...props };
  delete otherProps.sxProps;
  delete otherProps.dontHover;
  delete otherProps.icon;
  delete otherProps.iconProps;
  delete otherProps.scale;
  delete otherProps.enterDelay;
  delete otherProps.iconOther;*/

  return (
    <>
      {icon && (
        <Motion dontHover={dontHover} scale={scale}>
          <Tooltip
            title={
              tooltip ? (
                <span style={{ fontSize: "13px" }}>{tooltip}</span>
              ) : undefined
            }
            placement={placement}
            enterTouchDelay={100}
            enterDelay={enterDelay || 100}
            enterNextDelay={200}
          >
            <IcButton sx={[{}, sxProps || {}]} {...otherProps}>
              <Icon />
            </IcButton>
          </Tooltip>
        </Motion>
      )}
    </>
  );
}
