import { useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../../../App/App";
import { smallNavigation } from "../../../../Routes/Core/navigation";
import { useLocation } from "react-router-dom";

const SecondaryMain = React.lazy(() => import("./SecondaryNavMain"));
const SecondaryNavPhone = React.lazy(() => import("./SecondaryNavPhone"));

function SecondaryNavbar() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const path = useLocation().pathname;

  function getSmallRoutes() {
    if (!path) {
      return null;
    }
    for (let i = 0; i < smallNavigation.length; i++) {
      for (let j = 0; j < smallNavigation[i].routes.length; j++) {
        if (path == smallNavigation[i].routes[j].path) {
          return smallNavigation[i].routes;
        }
      }
    }
    return null;
  }

  if (!getSmallRoutes()) {
    return <></>;
  }

  return (
    <>
      {mobile ? (
        <SecondaryNavPhone getSmallRoutes={getSmallRoutes} path={path} />
      ) : (
        <SecondaryMain getSmallRoutes={getSmallRoutes} />
      )}
    </>
  );
}

export default React.memo(SecondaryNavbar);
