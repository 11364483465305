import { getDateFromString } from "../Classes/datesControl";
import predefined from "./sales-data";

function isObjectEmpty(sale) {
  if (!sale || !(typeof sale === "object") || Object.keys(sale).length === 0)
    return true;
  return false;
}

export function isDateInPast(date) {
  const now = new Date();
  return date < now;
}

function isSaleActive(sale) {
  if (sale?.ed) {
    try {
      const endDate = getDateFromString(sale.ed);
      if (isDateInPast(endDate)) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return true;
  }
}

export class Sale {
  constructor() {
    /*if (!dbData?.id) return {};
    this.createFromObject(this.#dbDataFinalize(dbData));*/
    return this;
  }

  dbDataFinalize(dbData) {
    const id = dbData?.id;
    if (!id) return dbData;
    const predData = id && predefined[id] ? predefined[id] : {};
    return { ...predData, ...dbData };
  }

  createFromObject(dbData) {
    Object.assign(this, this.dbDataFinalize(dbData));
    return this;
  }

  createFromTwoSales(userSale = {}, globSale = {}, usedSales = []) {
    let userSaleFinal = this.dbDataFinalize(userSale);
    let globSaleFinal = this.dbDataFinalize(globSale);

    if (
      (userSaleFinal?.uid && usedSales.includes(userSaleFinal.uid)) ||
      !isSaleActive(userSaleFinal)
    ) {
      userSaleFinal = {};
    }
    if (
      (globSaleFinal?.uid && usedSales.includes(globSaleFinal.uid)) ||
      !isSaleActive(globSaleFinal)
    ) {
      globSaleFinal = {};
    }

    // Two sales at once
    if (!isObjectEmpty(globSaleFinal) && !isObjectEmpty(userSaleFinal)) {
      // Have priority
      if (
        (userSaleFinal?.p || globSaleFinal?.p) &&
        (userSaleFinal?.p || 0) < (globSaleFinal?.p || 0)
      ) {
        Object.assign(this, { ...globSaleFinal, global: true });
      }
      // No priority = user sale
      else {
        Object.assign(this, { ...userSaleFinal, global: false });
      }
    }

    // Only global sale
    if (isObjectEmpty(userSaleFinal) && !isObjectEmpty(globSaleFinal)) {
      Object.assign(this, { ...globSaleFinal, global: true });
      return this;
    }

    // Only user sale
    if (isObjectEmpty(globSaleFinal) && !isObjectEmpty(userSaleFinal)) {
      Object.assign(this, { ...userSaleFinal, global: false });
      return this;
    }
    return this;
  }

  isValidSale() {
    const sale = this.getObject();
    if (isObjectEmpty(sale)) return false;
    if (!("a" in sale)) return false;
    return true;
  }

  getObject() {
    let object = {};
    Object.assign(object, this);
    return { ...object };
  }

  getFinalSaleObject() {
    return this.getObject();
  }
}
