import React, { Component, lazy } from "react";
import { logAnalytics } from "../../Resources/Firebase/init";
import { PageWrapper } from "../AppComponents/PrivateRoute";
import { withLocation } from "../../Resources/Routes/withLocation";
import * as Sentry from "@sentry/react";

const ErrorPage = lazy(() => import("./ErrorPage"));
const Navbar = lazy(() =>
  import("../../Resources/Core/Components/Navbars/Navbar")
);
const LoginNavbar = lazy(() =>
  import("../../Resources/Core/Components/Navbars/UserNavbars/LoginNavbar")
);
const BaseFooter = lazy(() =>
  import("../../Resources/Core/Components/Footer/BaseFooter")
);

// Shows error page
class ErrorHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorOnRoute: null,
    };
  }

  // Log catched errors to Sentry, analytics
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
    console.error("Error Boundary caught an error:", error, errorInfo);
    logAnalytics("error_caught", {
      message: error?.message || "",
      trace: error?.stack || "",
      stack: errorInfo?.componentStack || "-",
    });
    this.setState({ hasError: true, errorOnRoute: this.props.location });
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps?.location?.pathname;
    const path = this.props?.location?.pathname;
    if (this.state.hasError && prevPath && path && prevPath !== path) {
      this.setState({ hasError: false });
    }
  }

  render() {
    const route = this.props.route;

    if (this.state.hasError) {
      return (
        <PageWrapper>
          {!route.noNavbar && !route.public && (
            <Navbar currentPath={route.path} isPublic={route.public} />
          )}
          {route.public && !route.noNavbar && <LoginNavbar />}
          <ErrorPage />
          {!route.noFooter && <BaseFooter path={route.path} />}
        </PageWrapper>
      );
    }
    return this.props.children;
  }
}

export default withLocation(ErrorHandle);
