import React, { lazy } from "react";

const CenikPage = lazy(() =>
  import("../../Sales/PurchasePages/CenikPage/CenikPage")
);
const ShoppingBasket = lazy(() =>
  import("../../Sales/PurchasePages/ShoppingBasket/ShoppingBasket")
);
const ShopReturnPage = lazy(() =>
  import("../../Sales/PurchasePages/ShopReturnPage/ShopReturnPage")
);

const ShopPeriodRecheck = lazy(() =>
  import("../../Sales/PurchasePages/ShoppingBasket/ShopPeriodRecheck")
);

const shopRoutes = [
  {
    path: "/plany",
    element: <CenikPage />,
    public: false,
    noNavbar: true,
    permission: "null",
    //hideAd: true,
  },
  {
    path: "/shop",
    element: <ShoppingBasket />,
    noNavbar: true,
    public: true,
    permission: "null",
  },
  {
    path: "/shop_check",
    element: <ShopPeriodRecheck />,
    noNavbar: true,
    public: true,
    permission: "null",
  },
  {
    path: "/return_url", // return from gopay paygate
    element: <ShopReturnPage />,
    noNavbar: true,
    public: true,
  },
];

export default shopRoutes;
