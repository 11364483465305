import React, { lazy } from "react";

const RegisterInvite = lazy(() =>
  import("../../Core/Pages/Settings/Register/RegisterInvite")
);
const Login = lazy(() => import("../../Core/Pages/Settings/Login/Login"));
const Register = lazy(() =>
  import("../../Core/Pages/Settings/Register/Register")
);
const RegisterByProvider = lazy(() =>
  import("../../Core/Pages/Settings/Register/RegisterByProvider")
);
const Tutorial = lazy(() => import("../../Core/Pages/Tutorial/Tutorial"));
const TestLayout = lazy(() => import("./Test/TestLayout"));

// Account disabled
const AccountIsArchived = lazy(() =>
  import("../../Core/Pages/AccountDisable/Archive/AccountIsArchived")
);
const AccountIsDeleted = lazy(() =>
  import("../../Core/Pages/AccountDisable/Delete/AccountIsDeleted")
);
const AccountForceStopped = lazy(() =>
  import("../../Core/Pages/AccountDisable/AccountForceStopped")
);
const AccountArchiveSuccess = lazy(() =>
  import("../../Core/Pages/AccountDisable/Archive/AccountArchiveSuccess")
);
const AccountDeleteSuccess = lazy(() =>
  import("../../Core/Pages/AccountDisable/Delete/AccountDeleteSuccess")
);
const RequestDisableLayout = lazy(() =>
  import("../../Core/Pages/AccountDisable/RequestDisableLayout")
);
const DisabledAccountReenable = lazy(() =>
  import("../../Core/Pages/AccountDisable/DisabledAccountReenable")
);

const GoogleHandleScreen = lazy(() =>
  import("../../Core/Pages/Settings/Login/LoginButtons/GoogleHandleScreen")
);
const SubChooseLayout = lazy(() =>
  import(
    "../../Core/Pages/Settings/SubscriptionPage/LoginChooseComp/SubChooseLayout"
  )
);
const PermissionDeniedPage = lazy(() =>
  import(
    "../../Core/Pages/Settings/SubscriptionPage/SubUsers/PermissionDenied/PermissionDeniedPage"
  )
);

const baseRoutes = [
  {
    path: "/login",
    element: <Login />,
    public: true,
    //redirectIfAuth: "/faktura/list",
    noNavbar: true,
  },
  {
    path: "/registrace",
    element: <Register />,
    public: true,
    //redirectIfAuth: "/faktura/list",
    noNavbar: true,
  },
  {
    path: "/registrace_prov",
    element: <RegisterByProvider />,
    public: false,
    noNavbar: true,
  },
  {
    path: "/invite/:key",
    element: <RegisterInvite />,
    public: true,
    noNavbar: true,
  },
  {
    path: "/tutorial",
    element: <Tutorial />,
    noNavbar: true,
    //public: true,
  },
  {
    path: "/handle_google",
    element: <GoogleHandleScreen />,
    public: true,
  },
  {
    path: "/",
    element: <Login />,
    public: true,
    redirectIfAuth: "/faktura/list",
    noNavbar: true,
  },
  {
    path: "*",
    element: <Login />,
    public: true,
    redirectIfAuth: "/faktura/list",
    noNavbar: true,
  },
  // ACCOUNT ARCHIVATION
  {
    path: "/account/archived/enable",
    element: <DisabledAccountReenable />,
    public: true,
  },
  {
    path: "/account/reenable",
    element: <DisabledAccountReenable />,
    public: true,
  },
  {
    path: "/account/archived/success",
    element: <AccountArchiveSuccess />,
    public: true,
  },
  {
    path: "/account/deleted/success",
    element: <AccountDeleteSuccess />,
    public: true,
  },
  {
    path: "/account/archived",
    element: <AccountIsArchived />,
    public: true,
  },
  {
    path: "/account/deleted",
    element: <AccountIsDeleted />,
    public: true,
  },
  {
    path: "/account/stopped",
    element: <AccountForceStopped />,
    public: true,
  },
  {
    path: "/account/disable",
    element: <RequestDisableLayout />,
  },
  // SUBUSERS
  {
    path: "/denied",
    element: <PermissionDeniedPage />,
    public: true,
    noNavbar: true,
  },
  {
    path: "/sub/firmy",
    element: <SubChooseLayout />,
    noNavbar: true,
  },
  // TEST
  /*{
    path: "/test",
    element: <TestLayout />,
    public: true,
  },*/
];

export default baseRoutes;
