import React, { lazy } from "react";

const InfoEndpoint = lazy(() => import("./InfoEndpoint/InfoEndpoint"));
const AdEndpoint = lazy(() => import("./AdEndpoint/AdEndpoint"));
const UnsubscribeAds = lazy(() => import("./UnsubscripeAds/UnsubscribeAds"));

const endpointRoutes = [
  {
    path: "/view_info",
    element: <InfoEndpoint />,
    noNavbar: true,
    public: true,
  },
  {
    path: "/view_ad",
    element: <AdEndpoint />,
    noNavbar: true,
    public: true,
  },
  {
    path: "/noads",
    element: <UnsubscribeAds />,
    noNavbar: true,
    public: true,
  },
];

export default endpointRoutes;
