import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Sale } from "./Sale";
import { off, onValue, ref } from "firebase/database";
import { auth, logAnalytics, realtimeDb } from "../Firebase/init";
import SaleDialog from "../Core/Components/Ads/LightBox/SaleDialog";
import { getCoreRouteByPath } from "../Routes/routes";

function setShowSale(saleUid) {
  if (!localStorage || !saleUid) return;
  console.log("saved ", saleUid);
  localStorage.setItem(saleUid, true);
}

function didntShowSale(saleUid) {
  if (!localStorage || !saleUid) return true;
  return !Boolean(localStorage?.getItem(saleUid) || false);
}

function getUSaleData(uSale) {
  if (uSale && "sale" in uSale) {
    const { sale, ...other } = uSale;
    return other;
  }
  return uSale;
}

const SalesContext = createContext();

export const SalesContextProvider = ({ children, ...props }) => {
  const { userState, subuserData, claims, authLoading } = props;
  const location = useLocation();

  const [saleState, setSaleState] = useState({});

  const [gSale, setGSale] = useState(null);
  const [uSale, setUSale] = useState(null);

  const [saleShow, setSaleShow] = useState(false);

  const isSubuser = () => {
    return claims?.subuser;
  };

  const isUserLogged = () => {
    return Boolean(userState);
  };

  function showAd() {
    const route = getCoreRouteByPath(window.location.pathname);
    if (route?.public || window?.location?.pathname == "/plany") {
      return false;
    }
    return true;
  }

  const isThereValidSale = () => {
    return Boolean(saleState && Object.keys(saleState).length !== 0);
  };

  useEffect(() => {
    if (isThereValidSale() && didntShowSale(saleState?.uid) && showAd()) {
      logAnalytics("sale_view", {
        uid: auth?.currentUser?.uid,
        sale_uid: saleState?.uid,
      });

      setSaleShow(didntShowSale(saleState?.uid));
    } else {
      setSaleShow(false);
    }
  }, [saleState, location]);

  useEffect(() => {
    const newSale = new Sale()
      .createFromTwoSales(uSale?.sale, gSale, uSale?.used)
      .getObject();

    if (Boolean(newSale && Object.keys(newSale).length !== 0)) {
      setSaleState(newSale);
    }
  }, [uSale, gSale]);

  useEffect(() => {
    if (!isUserLogged() || isSubuser() || authLoading) return;

    const userSalesRef = ref(realtimeDb, "sales/" + userState.uid);
    const globalSalesRef = ref(realtimeDb, "sales/g");

    onValue(userSalesRef, (snapshot) => {
      const data = snapshot.val();

      let newSale = null;
      if (data) {
        newSale = new Sale().createFromObject(data?.sale || null);
      }
      //console.log("Users sale data ", data);
      setUSale({
        sale: newSale,
        used: data?.used || [],
        p: data?.p || null,
        l: data?.l || null,
      });
    });
    onValue(globalSalesRef, (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      const newSale = new Sale().createFromObject(data);
      //console.log("New global sale ", newSale);
      setGSale(newSale);
    });

    return () => {
      off(userSalesRef);
      off(globalSalesRef);
    };
  }, [userState, subuserData, claims, authLoading]);

  return (
    <>
      {saleState && (
        <SaleDialog
          open={saleShow}
          handleClose={() => {
            console.log("Saving showed ad: ", saleState?.uid);
            setShowSale(saleState?.uid);
            setSaleShow(false);
          }}
          data={saleState}
        />
      )}
      <SalesContext.Provider
        value={{
          saleData: saleState,
          userData: getUSaleData(uSale),
          isSale: isThereValidSale(),
        }}
      >
        {children}
      </SalesContext.Provider>
    </>
  );
};

export const useSalesContext = () => useContext(SalesContext);
